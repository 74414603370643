import {
  EntryMemberRequestControllerApi,
  QuerySelfEntryMemberRequestsRequest
} from '@rallycry/api-suite-typescript/dist/apis/EntryMemberRequestControllerApi'
import { AcceptDecline } from '@rallycry/api-suite-typescript/dist/models/AcceptDecline'
import { InviteApply } from '@rallycry/api-suite-typescript/dist/models/InviteApply'
import { useCompetitionMeta } from '../competition/useCompetitionMeta'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useUserAccount } from '../user/useUserAccount'
import { useCompetitionParticipant } from '../competition/useCompetitionParticipant'
import { useCompetitionTeamMembers } from './useCompetitionTeamMembers'
import { useController } from '@/core/hooks/useSWRApi'
import { useParsedParam } from '@/core/hooks/useRouteParams'

export const useCompetitionTeamSelfApplications = (
  options?: EntityOptions<QuerySelfEntryMemberRequestsRequest>
) => {
  const competitionId = useParsedParam('competitionId')
  const parsedId = useParsedParam('teamId')
  const { read: meta } = useCompetitionMeta()
  const { account } = useUserAccount()
  const { ctrl } = useController(EntryMemberRequestControllerApi)
  const { tryJoinWithCode } = useCompetitionParticipant()

  const teamId = options?.idOrKey || parsedId

  const {
    query: { mutate: mutateRoster }
  } = useCompetitionTeamMembers({ idOrKey: teamId })

  const entity = useQueryEntity({
    key: 'useCompetitionTeamSelfApplications',
    ...options,
    metas: [meta],
    request: {
      competitionId,
      kind: InviteApply.APPLY,
      status: AcceptDecline.PENDING,
      ...options?.request
    },
    read: (req, { entrant, requests }) =>
      ctrl({
        expectedStatuses: [401],
        fallbackResponse: { content: [] },
        metas: {
          entrantOrRequests: entrant || requests
        }
      }).querySelfEntryMemberRequests(req)
  })

  const apply = async (teamId: number, code?: string) => {
    if (await tryJoinWithCode(teamId, code)) {
      await meta.mutate()
      await mutateRoster()
      return
    }

    await ctrl({
      expectedStatuses: [422]
    }).createEntryMemberRequest({
      entryId: teamId,
      EntryMemberRequestCreateCommand: {
        kind: InviteApply.APPLY,
        recipient: account?.id!
      }
    })
    await meta.mutate()
  }

  const application = entity.flat.find(it => it.entry?.id === teamId)
  const withdraw = async () => {
    await ctrl().removeEntryMemberRequest({
      id: application?.id!
    })
    await meta.mutate()
  }

  return { ...entity, applications: entity.flat, application, apply, withdraw }
}
