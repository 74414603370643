import Button from '@mui/material/Button'
import { Issue } from '@rallycry/api-suite-typescript'
import { CompetitionState } from '@rallycry/api-suite-typescript/dist/models/CompetitionState'
import { EventKind } from '@rallycry/api-suite-typescript/dist/models/EventKind'
import { isEmpty, last, uniqBy } from 'lodash-es'
import moment from 'moment-timezone'
import { CheckInConfirm } from '../shared/CheckInConfirm'
import { RcTrans } from '@/components/atoms/RcTrans'
import { Countdown } from '@/components/molecules/text/Countdown'
import { ErrorCode } from '@/core/error-code'
import { ExpansionType, expand } from '@/core/expand'
import { useTime } from '@/core/hooks/useTime'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useCompetitionActions } from '@/entity/competition/useCompetitionActions'
import { useCompetitionEvents } from '@/entity/competition/useCompetitionEvents'
import { useCompetitionParticipant } from '@/entity/competition/useCompetitionParticipant'
import {
  TutorialItem,
  TutorialType
} from '@/entity/competition/useCompetitionTutorial'

export type CheckInType = {
  checkIn?: { dismissed?: boolean }
  event: {
    id: number
    name: string
    startDate: Date
    endDate: Date
    checkedIn: boolean
  }
}

export const isActiveCheckinIssue = (it: Issue, mostRecentCheckin?: number) =>
  it.code === ErrorCode.CheckInRequired &&
  (it.context?.checkIns as CheckInType[])?.find(
    that => that.event?.id === mostRecentCheckin && !that.checkIn
  )

export const useCompetitionAlertsCheckin = () => {
  const { getNow } = useTime()
  const { competition, isSoloCompetition } = useCompetition()
  const { isFullParticipant } = useCompetitionParticipant()
  const { events } = useCompetitionEvents()
  const { checkin } = useCompetitionActions()

  const issues: TutorialItem[] = []

  if (!isFullParticipant || competition?.state === CompetitionState.CONCLUDED) {
    return issues
  }

  const checkInEvents = events([EventKind.CHECKIN])

  const isActive = !!checkInEvents.current
  const currentOrPreviousCheckins =
    events([EventKind.CHECKIN]).allCurrentOrPrevious || []
  const mostRecentCheckin = last(currentOrPreviousCheckins)

  const participantEntry =
    competition?._viewInvolvement?.participantOf &&
    expand(
      competition?._viewInvolvement?.participantOf,
      competition?._expanded,
      ExpansionType.CompetitionEntry
    )
  const coachedEntries = competition?._viewInvolvement?.leaderOf || []
  const mergedEntries = participantEntry
    ? uniqBy([participantEntry, ...coachedEntries], it => it.id)
    : coachedEntries

  const now = getNow()

  for (const entry of mergedEntries) {
    // find checkin issue for mostRecentCheckin
    // determine if pending, missed, or dismissed

    const entryId = entry?.id
    const entryAlternateName = entry?.alternateName || '????'
    const checkinIssues =
      (competition?._issues?.find(
        it =>
          it.code === ErrorCode.CheckInRequired &&
          (it.context?.entry as any) === entryId
      )?.context?.checkIns as CheckInType[]) || []
    const checkIn = checkinIssues.find(
      it => it.event.id === mostRecentCheckin?.id
    )

    if (!checkIn || checkIn?.checkIn?.dismissed) {
      continue
    }

    if (!!checkIn.checkIn) {
      issues.push({
        header: <RcTrans i18nKey='competition:issues.checked-in-solo-header' />,
        type: TutorialType.TASK,
        severity: 'success',
        action: (
          <Button
            size='medium'
            color='secondary'
            onClick={() => checkin(checkIn.event.id, entryId, true)}
          >
            Dismiss
          </Button>
        ),
        children: isSoloCompetition ? (
          <RcTrans
            i18nKey='competition:issues.checked-in-solo'
            tOptions={{
              team: entryAlternateName,
              checkIn: checkIn.event.name
            }}
          />
        ) : (
          <RcTrans
            i18nKey='competition:issues.checked-in-team'
            tOptions={{
              team: entryAlternateName,
              checkIn: checkIn.event.name
            }}
          />
        )
      })
    } else if (now.isAfter(checkIn.event.endDate)) {
      issues.push({
        type: TutorialType.ALERT,
        severity: 'error',
        children: (
          <RcTrans
            i18nKey='competition:issues.missed-check-in'
            tOptions={{
              team: entryAlternateName,
              checkIn: checkIn.event.name
            }}
          />
        )
      })
    } else {
      issues.push({
        header: (
          <RcTrans i18nKey='competition:issues.check-in-required-header' />
        ),
        type: TutorialType.TASK,
        severity: 'warning',
        children: (
          <RcTrans
            i18nKey='competition:issues.check-in-required'
            tOptions={{
              team: entryAlternateName,
              checkIn: checkIn.event.name,
              duration: moment(checkIn.event.endDate).diff(
                checkIn.event.startDate,
                'm'
              ),
              time: moment(checkIn.event.startDate).format('LT')
            }}
          />
        ),
        action: (
          <CheckInConfirm checkinId={checkIn?.event?.id!} entryId={entryId} />
        )
      })
    }
  }

  const upcomingCheckins = checkInEvents.all.filter(
    it => moment(it.startDate).isAfter(now) && isEmpty([])
  )

  let upcomingFound = isActive
  for (const checkin of upcomingCheckins) {
    if (upcomingFound) break // only show one upcoming checkin
    upcomingFound = true
    issues.push({
      header: <RcTrans i18nKey='competition:issues.checkin-upcoming-header' />,
      type: TutorialType.TASK,
      dismissable: `${competition?.id}-${checkin?.id}-checkin`,
      severity: 'info',
      children: (
        <RcTrans
          i18nKey='competition:issues.checkin-upcoming'
          tOptions={{
            checkIn: checkin?.name,
            duration: moment(checkin?.endDate).diff(checkin?.startDate, 'm'),
            time: moment(checkin?.startDate).format('LT')
          }}
        />
      ),
      action: <Countdown target={checkin.startDate} />
    })
  }

  return issues
}
