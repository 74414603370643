'use client'

import { useHotkeys } from '@mantine/hooks'
import Menu from '@mui/material/Menu'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {
  CompetitionEventCreateCommand,
  CompetitionEventResource,
  CompetitionEventUpdateCommand
} from '@rallycry/api-suite-typescript/dist/models'
import moment from 'moment-timezone'
import {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState
} from 'react'
import {
  Calendar as BigCalendar,
  EventWrapperProps,
  SlotInfo,
  ToolbarProps,
  momentLocalizer
} from 'react-big-calendar'
import withDragAndDrop, {
  EventInteractionArgs
} from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import Tooltip from '@mui/material/Tooltip'
import { isEmpty, some } from 'lodash-es'
import LinearProgress from '@mui/material/LinearProgress'
import { CompetitionEventCalendarRow } from '../../pages/Competition/components/schedule/CompetitionEventCalendarRow'
import { ShadowBox } from '../../molecules/interactive/ShadowBox'
import { ModalCard } from '../modal/ModalCard'
import { BORDER_RADIUS, calendarTheme } from './calendarTheme'
import { CalendarToolbar } from './CalendarToolbar'
import { RcIcon } from '@/components/atoms/RcIcon'
import { RcTrans } from '@/components/atoms/RcTrans'
import {
  ActionMenuItems,
  ActionMenuOption
} from '@/components/molecules/interactive/ActionMenu'
import { calcElevationString } from '@/style/palette'
import { scrollIntoViewWithOffset, useIsMac } from '@/core/utils'
import { useTime } from '@/core/hooks/useTime'
import { CalendarKindIcon } from '@/components/organisms/calendar/CalendarKindIcon'
import { useOnScreen } from '@/core/hooks/useOnScreen'
import { useIsMdUp, useIsMobileDevice } from '@/core/hooks/useMediaQueries'
import { usePage } from '@/components/providers/site/PageProvider'
import { ModalTrigger } from '@/components/organisms/modal/ModalTrigger'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { useKeyPressed } from '@/core/hooks/useKeydown'

export const localizer = momentLocalizer(moment)
export const DnDCalendar = withDragAndDrop(BigCalendar)

export const Calendar = ({
  height = 700,
  editable,
  views,
  skipSidebar,
  events,
  create,
  update,
  remove,
  remind,
  onSceneCreate,
  createEdit,
  rowActions
}: {
  height?: number
  editable?: boolean
  views?: ('month' | 'week' | 'work_week' | 'day' | 'agenda')[]
  skipSidebar?: boolean
  events: CompetitionEventResource[]
  create: (cmd: CompetitionEventCreateCommand) => Promise<any>
  update: (id: number, cmd: CompetitionEventUpdateCommand) => Promise<void>
  remove: (id: number) => Promise<void>
  remind?: (id: number) => Promise<void>
  onSceneCreate?: (scene: CompetitionEventResource) => Promise<any>
  createEdit?: (
    handleClose: () => any,
    resource?: CompetitionEventResource
  ) => ReactNode
  rowActions?: (resource: CompetitionEventResource) => ReactNode
}) => {
  const { displayEventDateTime } = useTime()
  const [pasteProgress, setPasteProgress] = useState<number>(0)
  const [isCut, setIsCut] = useState(false)
  const [isPasteMode, setIsPasteMode] = useState(false)
  const [editingEvent, setEditingEvent] =
    useState<Partial<CompetitionEventResource>>()
  const [selectedEvent, setSelectedEvent] =
    useState<Partial<CompetitionEventResource>>()
  const [hoveredEvent, setHoveredEvent] =
    useState<Partial<CompetitionEventResource>>()
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(new Date())
  const [view, setView] = useState<
    'month' | 'week' | 'work_week' | 'day' | 'agenda'
  >('month')

  const isMac = useIsMac()
  const isMobileDevice = useIsMobileDevice()

  // track/control things' visibility on the screen
  const eventContainerBottomRef = useRef<HTMLDivElement>()
  const shouldScrollContainer = useOnScreen(eventContainerBottomRef, '50px')
  const calendarRef = useRef<HTMLDivElement>()
  const isCalendarVisible = useOnScreen(calendarRef, '5px')

  const isMdUp = useIsMdUp()
  const { featBroadcast } = useFeatures()
  const { getNow, displayDateSpan } = useTime()
  const { open, close } = usePage()

  // scroll calendar into view when events are selected on mobile
  useEffect(() => {
    if (selectedEvent && !isCalendarVisible)
      scrollIntoViewWithOffset(calendarRef.current!, 55)
    // disable isCalendarVisible on purpose for proper scroll up behavior on mobile
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEvent])

  const undoQueue = useRef<(() => any)[]>([])
  const handleUndo = () => {
    const undo = undoQueue.current.pop()
    if (undo) undo()
    close()
  }
  const undoButton = (
    <Button size='small' onClick={handleUndo}>
      Undo
    </Button>
  )

  useHotkeys(
    [
      ['escape', () => setIsPasteMode(false)],
      ['mod + z', () => handleUndo()],
      ['mod + c', () => handleCopy(hoveredEvent, false)],
      ['shift + mod + c', () => copyAllEvents()],
      ['mod + x', () => handleCopy(hoveredEvent, true)],
      ['mod + v', () => handleStartPasteMode()],
      [
        'mod + d',
        () =>
          handleEventDuplicate({
            start: hoveredEvent?.startDate,
            end: hoveredEvent?.endDate,
            event: hoveredEvent
          } as any)
      ],
      ['delete', () => handleEventDelete(hoveredEvent)]
    ],
    ['INPUT', 'TEXTAREA']
  )

  // when shift is down set cloning true
  const cloning = useKeyPressed('Shift')

  const handleEventDelete = async (event?: CompetitionEventResource) => {
    if (!event?.id) return

    await remove(event.id)
    undoQueue.current.push(() => create(event as any))
    setHoveredEvent(undefined)
    setSelectedEvent(undefined)

    open(undoButton, <>{event.name} deleted</>, 7000)
  }

  const handleEventDuplicate = async (
    args: EventInteractionArgs<CompetitionEventResource>
  ) => {
    if (!editable) return
    const startDate = new Date(args.start)
    const endDate = new Date(args.end)

    const res = await create({
      ...args.event,
      startDate,
      endDate,
      kind: args.event.kind!,
      name: args.event.name!
    })

    return res
    // undoQueue.current.push(() => remove(res.id!))
  }

  const handleEventUpdate = async (
    args: EventInteractionArgs<CompetitionEventResource>
  ) => {
    if (!editable) return
    const startDate = moment(args.start)
    const endDate = moment(args.end)

    if (Math.abs(startDate.diff(endDate, 'minutes')) < 15) {
      endDate.add(15, 'm')
    }

    await update(args.event.id!, {
      startDate: startDate.toDate(),
      endDate: endDate.toDate()
    })

    undoQueue.current.push(() =>
      update(args.event.id!, {
        startDate: args.event.startDate,
        endDate: args.event.endDate
      })
    )

    open(
      undoButton,
      <>
        {args.event.name} updated to {displayDateSpan(startDate, endDate)}
      </>,
      7000
    )
  }

  const getCopiedEvents = async () => {
    try {
      const clipboard = await navigator.clipboard.readText()
      return JSON.parse(clipboard) as CompetitionEventResource[]
    } catch (e) {
      return undefined
    }
  }

  const copyAllEvents = () => {
    navigator.clipboard.writeText(JSON.stringify(events))
  }

  const setCopiedEvent = (event?: CompetitionEventResource) => {
    event
      ? navigator.clipboard.writeText(JSON.stringify([event]))
      : navigator.clipboard.writeText('')
  }

  const handleCopy = async (
    event: CompetitionEventResource | undefined,
    isCut: boolean
  ) => {
    if (!event) return
    setCopiedEvent(event)
    setIsCut(isCut)
    open(
      undoButton,
      <>
        <Typography>
          {event.name} is in site clipboard. Use {modKey}+v to select a time
          range to paste.
        </Typography>
      </>,
      5000
    )
  }

  const handleStartPasteMode = async () => {
    const copiedEvent = await getCopiedEvents()
    if (!copiedEvent) return alert('No event(s) copied')
    setIsPasteMode(true)
  }

  const handlePaste = async (slot: SlotInfo) => {
    setIsPasteMode(false)
    setPasteProgress(0.01)

    const copiedEvents = await getCopiedEvents()
    if (!copiedEvents || isEmpty(copiedEvents)) return

    const firstEvent = moment(copiedEvents[0].startDate)
    const offsetSource =
      view === 'month'
        ? moment(slot.start)
            .hours(firstEvent.hour())
            .minutes(firstEvent.minute())
        : moment(slot.start)
    const offset = offsetSource.diff(firstEvent, 'seconds')

    const created: number[] = []
    for (const copiedEvent of copiedEvents) {
      const newStartDateTime = moment(copiedEvent.startDate)
        .add(offset, 'seconds')
        .toDate()
      const newEndDateTime = moment(copiedEvent.endDate)
        .add(offset, 'seconds')
        .toDate()

      if (isCut) {
        await handleEventUpdate({
          event: copiedEvent,
          start: newStartDateTime,
          end: newEndDateTime
        })
        setCopiedEvent(undefined)
        setIsCut(false)
      } else {
        const res = await handleEventDuplicate({
          event: copiedEvent,
          start: newStartDateTime,
          end: newEndDateTime
        })
        created.push(res.id!)
      }
      const index = copiedEvents.indexOf(copiedEvent)
      setPasteProgress(((index + 1) / copiedEvents.length) * 100)
    }

    if (some(created)) {
      undoQueue.current.push(() => {
        created.forEach(id => remove(id))
      })

      open(undoButton, <>{created.length} event(s) pasted</>, 7000)
    }
    setPasteProgress(0)
  }

  const handleEventEdit = async (event?: CompetitionEventResource) => {
    createEdit
      ? setEditingEvent(event)
      : event?.id
      ? update(event?.id!, {
          startDate: event?.startDate,
          endDate: event?.endDate
        })
      : create(event as any)
  }

  const handleEventSelection = (event: CompetitionEventResource) => {
    setSelectedEvent(event)
    if (!editable || view === 'month') {
      setSelectedDate(event.startDate!)
      setView('day')
      return
    }
    handleEventEdit(event)
  }

  const handleSlotSelection = (slot: SlotInfo) => {
    if (isPasteMode) return handlePaste(slot)

    if (view === 'month' && moment(slot.end).diff(slot.start, 'days') === 1) {
      setSelectedDate(slot.start)
      setView('day')
      return
    }

    if (!editable) {
      setSelectedDate(slot.start)
      setView('day')
      return
    }
    handleEventEdit({
      startDate: slot.start,
      endDate: slot.end
    })
  }

  const handleRemind = async (event: CompetitionEventResource) => {
    await remind?.(event.id!)
    open(null, <>{event.name} reminder sent to all participants.</>, 7000)
  }

  const [anchorEl, setAnchorEl] = useState<HTMLElement>()
  const [mouseX, setMouseX] = useState(0)
  const [mouseY, setMouseY] = useState(0)
  const [menuEvent, setMenuEvent] = useState<CompetitionEventResource>()

  const components = {
    toolbar: (props: ToolbarProps<CompetitionEventResource>) => (
      <CalendarToolbar {...props} />
    ),
    eventWrapper: ({
      event,
      children
    }: PropsWithChildren<EventWrapperProps<CompetitionEventResource>>) => {
      return (
        <Tooltip
          title={displayEventDateTime(event.startDate!, event.endDate)}
          followCursor
        >
          <Box
            onClick={() => handleEventSelection(event)}
            onMouseEnter={() => setHoveredEvent(event)}
            onMouseLeave={() => setHoveredEvent(undefined)}
            onContextMenu={e => {
              e.preventDefault()
              e.stopPropagation()
              setMenuEvent(event)
              setAnchorEl(e.currentTarget)
              setMouseX(e.clientX)
              setMouseY(e.clientY)
            }}
          >
            {children}
          </Box>
        </Tooltip>
      )
    }
  }

  const props: React.ComponentProps<typeof DnDCalendar> = {
    getNow: () => getNow()?.toDate(),
    step: 15,
    components: components,
    selected: selectedEvent,
    onSelectEvent: () => {}, // handled by event wrapper
    selectable: editable,
    onSelectSlot: handleSlotSelection,
    date: selectedDate,
    onNavigate: setSelectedDate,
    views: some(views)
      ? views
      : isMdUp
      ? ['month', 'week', 'day']
      : ['month', 'day'],
    view: some(views)
      ? views?.[0]
      : !isMdUp && view === 'week'
      ? 'month'
      : view,
    onView: setView,
    onEventDrop: cloning ? handleEventDuplicate : handleEventUpdate,
    onEventResize: handleEventUpdate,
    resizable: editable,
    localizer: localizer,
    events: events,
    scrollToTime: selectedEvent?.startDate || moment().hour(10).toDate(),
    eventPropGetter: (it: CompetitionEventResource) => ({
      className: it === hoveredEvent ? 'rbc-event-hovered' : 'rbc-event'
    }),
    titleAccessor: (it: any) =>
      (
        <Stack direction='row' alignItems='center' spacing={1}>
          <CalendarKindIcon kind={it.kind!} size='2xs' />
          <Typography variant='body2' noWrap fontSize='.8rem'>
            {it.name}
          </Typography>
        </Stack>
      ) as any,
    startAccessor: (it: CompetitionEventResource) => it.startDate!,
    endAccessor: (it: CompetitionEventResource) => it.endDate!,
    formats: {
      monthHeaderFormat: (date: Date) => {
        return moment(date).format(isMdUp ? 'MMMM YYYY' : 'MMM YYYY')
      },
      dayHeaderFormat: (date: Date) => {
        return moment(date).format(isMdUp ? 'dddd MMM DD' : 'ddd MMM DD')
      },
      weekdayFormat: (date: Date) => {
        return moment(date).format(isMdUp ? 'ddd' : 'dd')
      }
    },
    style: {
      height
    }
  }

  const modKey = isMac ? '⌘' : 'Ctrl'
  const iconProps = { inline: true, size: '1xs' as any }
  const menuItems = (
    menuEvent?: CompetitionEventResource
  ): ActionMenuOption[] => {
    const res: ActionMenuOption[] = []

    if (menuEvent && editable) {
      res.push(
        ...[
          {
            key: 'edit',
            display: 'Edit',
            icon: <RcIcon icon={['fal', 'calendar-edit']} {...iconProps} />,
            shortcut: 'Click/Drag',
            action: async () => handleEventEdit(menuEvent)
          },
          {
            key: 'cut',
            display: 'Cut',
            icon: <RcIcon icon={['fal', 'cut']} {...iconProps} />,
            shortcut: `${modKey}+X`,
            action: () => handleCopy(menuEvent, true)
          },
          {
            key: 'copy',
            display: 'Copy',
            icon: <RcIcon icon={['fal', 'copy']} {...iconProps} />,
            shortcut: `${modKey}+C`,
            action: async () => handleCopy(menuEvent, false)
          },

          {
            key: 'copy-all',
            display: 'Copy All Events',
            icon: <RcIcon icon={['fal', 'book-copy']} {...iconProps} />,
            shortcut: `shift+${modKey}+C`,
            action: async () => copyAllEvents()
          },
          {
            key: 'paste',
            display: 'Paste',
            icon: <RcIcon icon={['fal', 'paste']} {...iconProps} />,
            shortcut: `${modKey}+V`,
            action: async () => handleStartPasteMode()
          },
          {
            key: 'duplicate',
            display: 'Duplicate',
            icon: <RcIcon icon={['fal', 'clone']} {...iconProps} />,
            shortcut: `Shift+Drag or ${modKey}+D`,
            action: () =>
              handleEventDuplicate({
                start: menuEvent.startDate,
                end: menuEvent.endDate,
                event: menuEvent
              } as any)
          },
          {
            key: 'delete',
            display: 'Delete',
            icon: <RcIcon icon={['fal', 'trash-alt']} {...iconProps} />,
            shortcut: `Del`,
            action: () => handleEventDelete(menuEvent),
            divider: true
          }
        ]
      )
    }

    if (remind && menuEvent && editable) {
      res.push({
        key: 'remind',
        display: 'Send Reminder',
        icon: <RcIcon icon={['fal', 'bell']} {...iconProps} />,
        action: async () => handleRemind(menuEvent),
        divider: true
      })
    }

    if (!menuEvent && editable) {
      res.push({
        key: 'paste',
        display: 'Paste',
        icon: <RcIcon icon={['fal', 'paste']} {...iconProps} />,
        shortcut: `${modKey}+V`,
        action: async () => handleStartPasteMode()
      })
      res.push({
        key: 'copy-all',
        display: 'Copy All Events',
        icon: <RcIcon icon={['fal', 'book-copy']} {...iconProps} />,
        shortcut: `Ctrl+${modKey}+C`,
        action: async () => copyAllEvents(),
        divider: true
      })
    }

    if (editable) {
      res.push({
        key: 'create-event',
        display: <RcTrans i18nKey='shared.create-new-event' />,
        icon: <RcIcon icon={['fal', 'calendar-plus']} {...iconProps} />,
        action: async () =>
          handleEventEdit(
            selectedDate
              ? {
                  startDate: moment(selectedDate).add(12, 'hour').toDate(),
                  endDate: moment(selectedDate).add(13, 'hour').toDate()
                }
              : {}
          )
      })
    }

    if (featBroadcast && menuEvent && onSceneCreate) {
      res.push({
        key: 'create-scene',
        display: (
          <RcTrans i18nKey='competition:schedule.create-countdown-scene' />
        ),
        icon: <RcIcon icon={['fal', 'video']} {...iconProps} />,
        action: async () => onSceneCreate(menuEvent)
      })
    }

    return res
  }

  return (
    <Tooltip
      title={
        isPasteMode
          ? `Select a timeslot to paste ${
              isMobileDevice ? '' : '(ESC to cancel)'
            }`
          : undefined
      }
      followCursor
      onContextMenu={e => {
        e.preventDefault()
        setMenuEvent(hoveredEvent)
        setAnchorEl(anchorEl ? undefined : e.currentTarget)
        setMouseX(e.clientX)
        setMouseY(e.clientY)
      }}
    >
      <Grid container direction='row'>
        <Grid item sm={12} lg={skipSidebar ? 12 : 8} sx={{ width: '100%' }}>
          <Box sx={calendarTheme}>
            <Box ref={calendarRef} />
            {/* key with selected event to force scroll-to logic to fire on selectedEvent change */}
            {editable ? (
              <DnDCalendar key={selectedEvent?.id} {...props} />
            ) : (
              <BigCalendar key={selectedEvent?.id} {...props} />
            )}
          </Box>

          <ModalTrigger
            open={!!editingEvent}
            onClose={() => handleEventEdit(undefined)}
          >
            {({ handleClose }) => createEdit?.(handleClose, editingEvent)}
          </ModalTrigger>
        </Grid>
        {skipSidebar ? null : (
          <Grid item xs={12} lg={4}>
            <Box
              sx={theme => ({
                mt: { xs: 2, lg: theme.spacing(12.5) },
                ml: { xs: 0, lg: 2 }
              })}
            >
              {editable && createEdit ? (
                <ModalTrigger
                  activation={handleOpen => (
                    <Button
                      color='primary'
                      variant='outlined'
                      onClick={handleOpen}
                      sx={theme => ({
                        width: '100%',
                        py: 5,
                        borderRadius: BORDER_RADIUS
                      })}
                    >
                      <RcIcon icon={['fal', 'plus']} inline mr={2} />
                      <RcTrans i18nKey='shared.create-new-event' />
                    </Button>
                  )}
                >
                  {({ handleClose }) => createEdit?.(handleClose)}
                </ModalTrigger>
              ) : null}
              <ShadowBox
                sx={theme => ({
                  mt: theme.spacing(2),
                  height: {
                    xs: undefined,
                    lg: `calc(${height}px - ${theme.spacing(
                      editable ? 29.5 : 13
                    )})`
                  }
                })}
              >
                <Stack direction='column' spacing={2}>
                  {events.map(it => (
                    <Box
                      key={it.id}
                      onMouseEnter={ev => setHoveredEvent(it)}
                      onMouseLeave={() => setHoveredEvent(undefined)}
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        setSelectedEvent(it)
                        setSelectedDate(it.startDate)
                        const diff = moment(it.endDate).diff(
                          it.startDate,
                          'days'
                        )
                        if (diff > 7) {
                          setView('month')
                        } else if (diff > 1) {
                          setView('week')
                        } else {
                          setView('day')
                        }
                      }}
                    >
                      <CompetitionEventCalendarRow
                        options={menuItems(it)}
                        rowActions={rowActions}
                        resource={it}
                        hovered={hoveredEvent === it}
                        shouldScroll={shouldScrollContainer}
                      />
                    </Box>
                  ))}
                </Stack>
              </ShadowBox>
              <Box ref={eventContainerBottomRef} />
            </Box>
          </Grid>
        )}
        <Menu
          id='calendar-context'
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(undefined)}
          anchorReference='anchorPosition'
          anchorPosition={{ top: mouseY, left: mouseX }}
        >
          <ActionMenuItems
            options={menuItems(menuEvent)}
            onAction={it => {
              setAnchorEl(undefined)
              it.action?.()
            }}
          />
        </Menu>
        <ModalCard
          title={
            pasteProgress === 1
              ? 'Successfully Pasted Events'
              : 'Pasting Events...'
          }
          open={pasteProgress > 0}
          onClose={() => setPasteProgress(0)}
        >
          <Stack direction='column' spacing={2} p={3}>
            <Typography variant='h4'>
              Pasting {Math.round(pasteProgress)}%
            </Typography>
            <LinearProgress variant='determinate' value={pasteProgress} />
          </Stack>
        </ModalCard>
      </Grid>
    </Tooltip>
  )
}
