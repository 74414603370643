import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { lighten, useTheme } from '@mui/material/styles'
import { useLayoutEffect, useRef, useState } from 'react'
import { TwitchEmbed } from 'react-twitch-embed'
import Color from 'color'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { isDarkMode, scrollIntoViewWithOffset } from '@/core/utils'
import { RcIcon } from '@/components/atoms/RcIcon'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useQuery } from '@/core/hooks/useQuery'

export const TWITCH_PURPLE = '#6441a5'

export const TwitchAlert = ({ identifier }: { identifier: string }) => {
  const ref = useRef<HTMLElement>()
  const q = useQuery()
  const [expanded, setExpanded] = useState(q?.get('stream') === identifier)
  const theme = useTheme()

  useLayoutEffect(() => {
    if (!expanded) return
    scrollIntoViewWithOffset(ref.current, 10)
  }, [expanded])

  const expandAction = (
    <>
      {expanded ? null : (
        <Button
          size='small'
          variant='contained'
          onClick={() => {
            setExpanded(true)
          }}
          sx={{
            color: theme.palette.getContrastText(TWITCH_PURPLE),
            mr: 2,
            backgroundColor: TWITCH_PURPLE,
            '&:hover': {
              backgroundColor: Color(TWITCH_PURPLE).lighten(0.2).toString()
            }
          }}
        >
          <RcTrans i18nKey='shared.watch-now' />
        </Button>
      )}
      <RcIconButton
        onClick={() => {
          setExpanded(e => !e)
        }}
        icon={['fal', 'chevron-down']}
        size='small'
        sx={theme => ({
          transform: expanded ? 'rotate(180deg)' : undefined,
          transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest
          })
        })}
      />
    </>
  )

  return (
    <Alert
      elevation={3}
      action={expandAction}
      variant={'outlined'}
      icon={expanded ? false : <RcIcon icon={['fab', 'twitch']} />}
      sx={theme => ({
        position: 'relative',
        p: expanded ? 0 : undefined,
        color: isDarkMode(theme) ? lighten(TWITCH_PURPLE, 0.5) : TWITCH_PURPLE,
        backgroundColor: isDarkMode(theme)
          ? lighten(TWITCH_PURPLE, 0.5)
          : TWITCH_PURPLE,
        borderColor: TWITCH_PURPLE,
        '& .MuiAlert-message': {
          width: '100%',
          padding: expanded ? 0 : undefined
        },
        '& .MuiAlert-icon': {
          color: isDarkMode(theme) ? lighten(TWITCH_PURPLE, 0.5) : TWITCH_PURPLE
        },
        '& .MuiAlert-action': {
          ...(expanded
            ? {
                position: 'absolute',
                top: 10,
                right: 15
              }
            : {}),
          alignSelf: expanded ? 'flex-start' : 'center'
        }
      })}
    >
      <Box ref={ref} />

      {expanded ? (
        <TwitchEmbed
          channel={identifier}
          autoplay
          darkMode={true}
          withChat={false}
          style={{ width: '100%', aspectRatio: 16 / 9 }}
        />
      ) : (
        <RcTrans i18nKey='shared.now-streaming' tOptions={{ identifier }} />
      )}
    </Alert>
  )
}
