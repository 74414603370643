import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import { AccessLevel } from '@rallycry/api-suite-typescript/dist/models/AccessLevel'
import { CommunityMemberDocument } from '@rallycry/api-suite-typescript/dist/models/CommunityMemberDocument'
import { ContactAccountResource } from '@rallycry/api-suite-typescript/dist/models/ContactAccountResource'
import { SidType } from '@rallycry/api-suite-typescript/dist/models/SidType'
import { UserResource } from '@rallycry/api-suite-typescript/dist/models/UserResource'
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { ModalCard } from '../modal/ModalCard'
import { ModalProvider } from '../modal/ModalProvider'
import { UserViewer } from '../user/UserViewer'
import { ContactFlowLink } from '@/flows/Site/ContactFlow/ContactFlowLink'
import { getUserDisplayName } from '@/core/utils'
import { RouteParamOverrideProvider } from '@/components/providers/site/RouteParamOverrideProvider'
import {
  AvatarText,
  AvatarTextProps
} from '@/components/molecules/text/AvatarText'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcSuspense } from '@/components/atoms/RcSuspense'

/*
 * Avatar wrapper that provides user modal when clicked. Also merges additional contacts into modal for match views.
 */
interface UserAvatarProps extends AvatarTextProps {
  user: UserResource
  loadCompetitionContacts?: boolean
  loadTeamContacts?: boolean
  extra?: React.ReactNode
  contacts?: ContactAccountResource[]
  textColor?: string
  disableClick?: boolean
  rcVariant?: 'chip' | 'text' | 'avatar' | 'standard'
}

export const UserAvatar = forwardRef(function UserAvatar(
  {
    contacts,
    loadCompetitionContacts,
    loadTeamContacts,
    user,
    extra,
    textColor,
    rcVariant = 'standard',
    disableClick,
    ...avatarProps
  }: UserAvatarProps,
  ref
) {
  const [open, setOpen] = useState(false)

  const handleOpen = async () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useImperativeHandle(ref, () => ({ open: () => handleOpen() }))

  const hasFullName = !!user?.givenName
  const displayName = getUserDisplayName(user)

  const standardOrAvatar = () => (
    <AvatarText
      src={user?.image}
      alt='User avatar'
      isOnline={user?.activeRecently}
      onClick={
        disableClick
          ? undefined
          : ev => {
              ev.stopPropagation()
              return handleOpen()
            }
      }
      avatarChildren={user?.name?.charAt(0)?.toLocaleUpperCase()}
      {...avatarProps}
      badgeIcon={
        user?.ownerType === SidType.COMMUNITYOFFICER
          ? ['fal', 'ghost']
          : avatarProps.badgeIcon
      }
      badgeTooltip={
        user?.ownerType === SidType.COMMUNITYOFFICER ? (
          <RcTrans i18nKey='shared.placeholder-profile' />
        ) : (
          avatarProps.badgeTooltip
        )
      }
    >
      {rcVariant === 'avatar' ? null : (
        <>
          <Typography variant='body0' noWrap textAlign='left'>
            {displayName}
          </Typography>
          {extra ? (
            extra
          ) : hasFullName ? (
            <Typography
              variant='subtitle2'
              color={textColor || 'text.secondary'}
              textAlign='left'
              sx={{ mt: 1 }}
            >
              {user?.name}
            </Typography>
          ) : null}
        </>
      )}
    </AvatarText>
  )

  return (
    <>
      {{
        chip: () => (
          <Chip
            label={`@${user?.name}`}
            onClick={() => handleOpen()}
            color='primary'
            sx={{
              cursor: 'pointer',
              '&:hover': { textDecoration: 'underline' }
            }}
          />
        ),
        text: () => (
          <Box
            display='inline'
            onClick={ev => {
              ev.stopPropagation()
              return handleOpen()
            }}
            sx={{
              cursor: 'pointer',
              '&:hover': { textDecoration: 'underline' }
            }}
          >
            {user?.name}
          </Box>
        ),
        avatar: standardOrAvatar,
        standard: standardOrAvatar
      }[rcVariant]()}

      <ModalProvider>
        <ModalCard
          open={open}
          onClose={handleClose}
          noFooter
          noHeader
          noPadding
        >
          {open ? (
            <RcSuspense height={600}>
              <ErrorBoundary
                fallback={
                  <>
                    <Typography color='text2' align='center'>
                      <RcTrans i18nKey='site.forbidden-page-content'>
                        <ContactFlowLink isBlockedLayout />
                      </RcTrans>
                    </Typography>
                  </>
                }
              >
                <RouteParamOverrideProvider
                  context={{ userId: user?.id?.toString() }}
                >
                  <UserViewer
                    userId={user?.id}
                    contacts={contacts}
                    isModal
                    loadCompetitionContacts={loadCompetitionContacts}
                    loadTeamContacts={loadTeamContacts}
                  />
                </RouteParamOverrideProvider>
              </ErrorBoundary>
            </RcSuspense>
          ) : null}
        </ModalCard>
      </ModalProvider>
    </>
  )
})

interface CommunityMemberDocumentAvatarProps
  extends Omit<UserAvatarProps, 'user'> {
  member: CommunityMemberDocument
}

export const CommunityMemberDocumentAvatar = forwardRef(
  function communityMemberDocumentAvatar(
    { member, ...entityAvatarProps }: CommunityMemberDocumentAvatarProps,
    ref
  ) {
    return (
      <UserAvatar
        {...entityAvatarProps}
        ref={ref}
        user={member.member!}
        badgeTooltip={member.level}
        badgeIcon={
          member.level === AccessLevel.ADMINISTRATOR
            ? ['fal', 'flag']
            : member.level === AccessLevel.MODERATOR
            ? ['fal', 'pennant']
            : undefined
        }
      />
    )
  }
)
