import { Theme } from '@mui/material'
import { insert } from 'formik'
import { calcElevationString } from '@/style/palette'

export const spinOnHover = {
  '&:hover': {
    '& svg': {
      animation: 'spin .5s linear'
    }
  },
  '@keyframes spin': {
    '100%': {
      transform: 'rotate(180deg)'
    }
  }
}

export const borderAnimation = (theme: Theme) => ({
  position: 'absolute',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    background: 'conic-gradient(transparent 270deg, white, transparent)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    aspectRatio: 1,
    width: '100%',
    animation: 'border 2s linear infinite'
  },
  '&::after': {
    content: '""',
    background: 'inherit',
    borderRadius: 'inherit',
    position: 'absolute',
    inset: 1,
    height: 'calc(100% - 2px)',
    width: 'calc(100% - 2px)'
  },
  '@keyframes border': {
    '0%': {
      transform: 'translate(-50%, -50%) scale(1.4) rotate(0turn)'
    },
    // '25%': {
    //   transform: 'translate(-50%, -50%) scale(1.4) rotate(.4turn)'
    // },
    '50%': {
      transform: 'translate(-50%, -50%) scale(1.4) rotate(.5turn)'
    },
    // '75%': {
    //   transform: 'translate(-50%, -50%) scale(1.4) rotate(.8turn)'
    // },
    '100%': {
      transform: 'translate(-50%, -50%) scale(1.4) rotate(1turn)'
    }
  }
})

export const shiver = {
  '&:hover': {
    '& svg': {
      animation: 'shiver .5s ease-in-out'
    }
  },
  '@keyframes shiver': {
    '25%': {
      transform: 'skewX(-4deg)',
      transformOrigin: 'bottom center'
    },
    '75%': {
      transform: 'skewX(4deg)',
      transformOrigin: 'bottom center'
    }
  }
}

export const dingle = {
  '&:hover': {
    '& .MuiBadge-root': {
      animation: 'dingle .5s ease-in-out'
    }
  },
  '@keyframes dingle': {
    '25%': {
      transform: 'rotate(-10deg)',
      transformOrigin: 'top center'
    },
    '75%': {
      transform: 'rotate(10deg)',
      transformOrigin: 'top center'
    }
  }
}

export const pulse = {
  '&': {
    animation: 'pulse 1.5s infinite linear'
  },
  '@keyframes pulse': {
    '50%': {
      backgroundColor: 'rgba(125, 125, 125, 0.4)'
    }
  }
}

export const sneakySean = {
  '&:hover': {
    '& svg': {
      animation: 'sneak 1.5s linear'
    }
  },
  '@keyframes sneak': {
    '25%': {
      transform: 'skewX(-15deg)',
      transformOrigin: 'bottom '
    },
    '75%': {
      transform: 'skewX(15deg)',
      transformOrigin: 'bottom '
    }
  }
}

export const swirlySx = {
  animation: 'swirly-bg 10s infinite ease-in-out',
  '@keyframes swirly-bg': {
    '0%': { backgroundPosition: '0 0', backgroundSize: '100%' },
    '50%': { backgroundPosition: '10% 2%', backgroundSize: '110%' },
    '100%': { backgroundPosition: '0 0', backgroundSize: '100%' }
  }
}
