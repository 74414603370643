import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import { isEmpty, last } from 'lodash-es'
import { useRef } from 'react'
import Skeleton from '@mui/material/Skeleton'
import { EventKind } from '@rallycry/api-suite-typescript/dist/models'
import { isActiveCheckinIssue } from '../alerts/useCompetitionAlertsCheckin'
import { RcIcon } from '@/components/atoms/RcIcon'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useNavigation } from '@/core/hooks/useNavigation'
import { useOnScreen } from '@/core/hooks/useOnScreen'
import {
  CompetitionRoute,
  CompetitionSettingsRoute,
  RootRoute
} from '@/core/route-keys'
import { useCompetitionTeam } from '@/entity/competition-team/useCompetitionTeam'
import { PreventParentClick } from '@/components/molecules/interactive/PreventParentClick'
import { ErrorCode } from '@/core/error-code'
import { useCompetitionEvents } from '@/entity/competition/useCompetitionEvents'

// t('competition:team.issues-found', { count })
interface TeamIssueTooltipProps {
  teamId: number
  hideSuccess?: boolean
}

export const TeamIssueTooltip = ({
  teamId,
  hideSuccess
}: TeamIssueTooltipProps) => {
  const ref = useRef<HTMLDivElement>()
  const scrolled = useOnScreen(ref, '50px')
  const shouldLoad = scrolled
  const { navTo } = useNavigation()
  const {
    team,
    read: { isValidating }
  } = useCompetitionTeam({
    paused: !shouldLoad,
    idOrKey: teamId,
    suspense: false,
    mutable: true,
    dedupingInterval: 15000
  })
  const { events } = useCompetitionEvents()
  const currentOrPreviousCheckins =
    events([EventKind.CHECKIN]).allCurrentOrPrevious?.map(it => it.id!) || []

  const mostRecentCheckin = last(currentOrPreviousCheckins)

  const filtered = team?._issues?.filter(
    it =>
      it.code !== ErrorCode.CheckInRequired ||
      isActiveCheckinIssue(it, mostRecentCheckin)
  )

  if (hideSuccess && isEmpty(filtered)) {
    return null
  }

  return (
    <Box>
      <Box ref={ref} />

      {!team || isValidating ? (
        <Skeleton variant='circular' width={16} height={16} />
      ) : isEmpty(filtered) ? (
        <Tooltip title='Team has no issues' placement='top' enterTouchDelay={0}>
          <span>
            <RcIcon icon={['fal', 'check']} color='success' />
          </span>
        </Tooltip>
      ) : (
        <Tooltip
          title={
            <PreventParentClick>
              <Box color='warning.main' p={2}>
                <Typography variant='body2' pb={1}>
                  <RcTrans
                    i18nKey='competition:team.issues-found'
                    tOptions={{ count: filtered?.length }}
                  />
                  <ul style={{ marginBlock: 1 }}>
                    {filtered?.map(it => {
                      const msg = it.message as any
                      return <li key={msg}>{msg}</li>
                    })}
                  </ul>
                </Typography>
                <Button
                  color='warning'
                  variant='outlined'
                  size='small'
                  onClick={() =>
                    navTo({
                      root: RootRoute.Competition,
                      rootId: team?.competition?.id,
                      subRoute: CompetitionRoute.Settings,
                      subId: CompetitionSettingsRoute.ManageTeams
                    })
                  }
                >
                  Manage Teams
                </Button>
              </Box>
            </PreventParentClick>
          }
          placement='top'
          enterTouchDelay={0}
        >
          <span>
            <RcIcon icon={['fal', 'triangle-exclamation']} color='warning' />
          </span>
        </Tooltip>
      )}
    </Box>
  )
}
