import { BracketMatchControllerApi } from '@rallycry/api-suite-typescript/dist/apis/BracketMatchControllerApi'
import { BracketMatchUpdateCommand } from '@rallycry/api-suite-typescript/dist/models/BracketMatchUpdateCommand'
import { EntityOptions, useReadEntity } from '../useEntity'
import { useBracketMatchMeta } from './useBracketMatchMeta'
import { useParsedParam } from '@/core/hooks/useRouteParams'
import { useController } from '@/core/hooks/useSWRApi'

export const useBracketMatch = (options?: EntityOptions) => {
  const id = useParsedParam('matchId')
  const { read: meta } = useBracketMatchMeta(options)
  const { ctrl } = useController(BracketMatchControllerApi)

  const matchId = Number(options?.idOrKey) || id
  const entity = useReadEntity({
    key: 'useBracketMatch',
    expand:
      '_links,activeChannel,bracket{settings,competition},assignments{entry{leader,_links,representing{additionalOrganizations,profile}}},event,games,channel',
    ...options,
    idOrKey: matchId,
    metas: [meta],
    read: (req, { root }) => ctrl({ metas: { root } }).readBracketMatch(req),
    update: (id, cmd: BracketMatchUpdateCommand) =>
      ctrl().updateBracketMatch({ id, AdvancedMatchUpdateCommand: cmd })
  })

  const setWinner = async (winner?: number) => {
    await ctrl().updateBracketMatch({
      id: matchId,
      AdvancedMatchUpdateCommand: { winner: winner || (null as any) }
    })
    await meta.mutate()
  }

  const reset = async () => {
    await ctrl().resetBracketMatch({
      id: matchId,
      BracketMatchResetCommand: {}
    })
    await meta.mutate()
  }

  const remove = async () => {
    await ctrl().removeBracketMatch({ id: matchId })
    await meta.mutate()
  }

  return {
    ...entity,
    match: entity.read.data,
    setWinner,
    reset,
    remove
  }
}
