import Stack from '@mui/material/Stack'
import {
  BracketMatchResource,
  MatchState
} from '@rallycry/api-suite-typescript'
import { orderBy } from 'lodash-es'
import { MatchCard } from '../match/MatchCard'
import { PageItem } from '@/components/organisms/page/PageItem'
import { PageItemContent } from '@/components/organisms/page/PageItemContent'
import { useParsedParam } from '@/core/hooks/useRouteParams'
import { useBracketMatchesByTeam } from '@/entity/bracket-match/useBracketMatchesByTeam'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useCompetitionParticipant } from '@/entity/competition/useCompetitionParticipant'

export const TeamMatches = () => {
  const parsedId = useParsedParam('teamId')
  const { competition } = useCompetition()
  const { participant } = useCompetitionParticipant()
  const { matches } = useBracketMatchesByTeam({ request: { id: parsedId } })

  const sorted = orderBy(matches, ['id'], ['desc'])

  const displayMatches = (matches: BracketMatchResource[]) =>
    matches.map(it => {
      const isComplete = it.state === MatchState.COMPLETE
      return (
        <MatchCard
          key={it.id}
          match={it}
          competition={competition}
          participant={participant}
        />
      )
    })

  return (
    <PageItem contained={false} fillContent>
      <PageItemContent>
        <Stack direction='column' spacing={3}>
          {displayMatches(sorted)}
        </Stack>
      </PageItemContent>
    </PageItem>
  )
}
