'use client'
import { Theme } from '@mui/material'
import { calcElevationString } from '@/style/palette'

export const BORDER_RADIUS = '10px'

export const calendarTheme = (theme: Theme) => {
  const border = `.5px solid ${theme.palette.divider}`
  return {
    '& .rbc-calendar': {
      overflow: 'hidden'
    },
    // THINGS WITH COLORS
    '& .rbc-today': {
      backgroundColor: theme.palette.background.paper,
      backgroundImage: calcElevationString(theme.palette.mode, [4])
    },
    '& .rbc-event': {
      fontSize: '0.81rem',
      backgroundColor: theme.palette.background.paper,
      backgroundImage: calcElevationString(theme.palette.mode, [8]),
      color: theme.palette.text.primary
    },
    '& .rbc-event-hovered': {
      fontSize: '0.81rem',
      backgroundColor: theme.palette.background.paper,
      backgroundImage: calcElevationString(theme.palette.mode, [11]),
      color: theme.palette.text.primary
    },
    '& .rbc-time-header': {
      borderRight: `1px solid transparent`,
      backgroundColor: theme.palette.background.paper,
      backgroundImage: calcElevationString(theme.palette.mode, [4]),
      borderRadius: BORDER_RADIUS,
      marginRight: '0 !important',
      paddingRight: '7px'
    },
    '& .rbc-month-view': {
      border: 0,
      borderRadius: BORDER_RADIUS,
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
      backgroundImage: calcElevationString(theme.palette.mode, [2])
    },
    '& .rbc-time-view': {
      border: 0,
      backgroundColor: theme.palette.background.paper,
      backgroundImage: calcElevationString(theme.palette.mode, [2]),
      borderRadius: BORDER_RADIUS
    },
    '& .rbc-time-view-resources .rbc-time-gutter, & .rbc-time-view-resources .rbc-time-header-gutter':
      {
        backgroundColor: 'transparent'
      },
    // THINGS WITH TEXT
    '& .rbc-month-header': {
      backgroundColor: theme.palette.background.paper,
      backgroundImage: calcElevationString(theme.palette.mode, [3]),
      borderRadius: BORDER_RADIUS,
      ...theme.typography.body2
    },
    '& .rbc-header': {
      p: 4,
      border: 0,
      textOverflow: 'unset',
      color: theme.palette.text.primary
    },
    '& .rbc-label': {
      color: theme.palette.text.primary,
      ...theme.typography.body2
    },
    '& .rbc-date-cell': {
      textAlign: 'left',
      paddingLeft: 1,
      color: theme.palette.text.primary,
      '&.rbc-off-range': {
        color: theme.palette.text.secondary
      }
    },
    '& .rbc-show-more': {
      backgroundColor: 'transparent',
      color: theme.palette.text.secondary,
      fontSize: '0.65rem',
      fontWeight: 500
    },
    // THINGS WITH BORDERS
    '& .rbc-time-header-content': {
      borderLeft: border
    },
    // intra day dividers
    '& .rbc-day-bg + .rbc-day-bg': {
      borderLeft: border
    },
    // intra week dividers
    '& .rbc-month-row + .rbc-month-row': {
      borderTop: border
    },
    // rbc timeslot only show border on 1st child
    '& .rbc-time-slot': {
      borderTop: border,
      '&:not(:first-of-type)': {
        borderTop: 'none'
      }
    },
    // remove extra left border for weekly/daily
    '& .rbc-time-gutter > .rbc-timeslot-group': {
      borderLeft: 0
    },
    // remove extra border on non-dnd view weekly/daily
    '& .rbc-time-content > * + * > *': {
      borderLeft: 0
    },
    '& .rbc-timeslot-group': {
      borderLeft: border,
      borderBottom: border
    },
    '& .rbc-off-range-bg': {
      backgroundColor: 'transparent'
    },
    '& .rbc-time-content': {
      borderTop: '1px solid transparent',
      borderBottomLeftRadius: BORDER_RADIUS,
      overflowX: 'hidden'
    },
    '& .rbc-event-label': {
      display: 'none'
    }
  }
}
