import {
  CompetitionParticipantControllerApi,
  ViewCompetitionParticipantContactAccountsRequest
} from '@rallycry/api-suite-typescript/dist/apis/CompetitionParticipantControllerApi'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useParsedParam } from '@/core/hooks/useRouteParams'
import { useController } from '@/core/hooks/useSWRApi'

export const useCompetitionParticipantContactAccounts = (
  options?: EntityOptions<ViewCompetitionParticipantContactAccountsRequest>
) => {
  const competitionId = useParsedParam('competitionId')
  const { ctrl } = useController(CompetitionParticipantControllerApi)

  const entity = useQueryEntity({
    key: 'useCompetitionParticipantContactAccounts',
    expand: 'content{_links}',
    ...options,
    request:
      competitionId && options?.idOrKey
        ? { competitionId, participantId: options?.idOrKey }
        : undefined,
    read: req =>
      ctrl({
        expectedStatuses: [403, 404],
        fallbackResponse: { content: [] }
      }).viewCompetitionParticipantContactAccounts(req)
  })

  return { ...entity, accounts: entity.flat }
}
