import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { CompetitionEventResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionEventResource'
import { ReactNode, useEffect, useRef } from 'react'
import { CalendarKindIcon } from '../../../../organisms/calendar/CalendarKindIcon'
import { useTime } from '@/core/hooks/useTime'
import {
  ActionMenu,
  ActionMenuOption
} from '@/components/molecules/interactive/ActionMenu'

export const CompetitionEventCalendarRow = ({
  options,
  rowActions,
  resource,
  hovered,
  shouldScroll
}: {
  options: ActionMenuOption[]
  rowActions?: (resource: CompetitionEventResource) => ReactNode
  resource: CompetitionEventResource
  hovered?: boolean
  shouldScroll?: boolean
}) => {
  const ref = useRef<any>()
  const { displayEventDateTime } = useTime()
  const name = () => resource.name || resource.kind
  const time = () => displayEventDateTime(resource.startDate!, resource.endDate)

  useEffect(() => {
    if (hovered && shouldScroll) {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        inline: 'nearest',
        block: 'nearest'
      })
    }
  }, [hovered, shouldScroll])

  const actions = (
    <Stack
      direction='row'
      spacing={1}
      justifyContent='flex-end'
      alignItems='center'
    >
      {rowActions?.(resource)}
      <ActionMenu options={options} buttonProps={{ size: 'small' }} />
    </Stack>
  )

  return (
    <Card ref={ref} elevation={hovered ? 10 : 2} sx={{ p: 2 }}>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={1}
      >
        <CalendarKindIcon kind={resource.kind!} />
        <Card
          elevation={hovered ? 7 : 3}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            py: 2,
            px: 3,
            flexGrow: 1
          }}
        >
          <Stack direction='column'>
            <Typography variant='subtitle2'>{name()}</Typography>
            <Typography variant='body2' color='text.secondary'>
              {time()}
            </Typography>
          </Stack>
        </Card>
        {actions}
      </Stack>
    </Card>
  )
}
