'use client'

import Box, { BoxProps } from '@mui/material/Box'
import { useRef } from 'react'
import { isFunction } from 'lodash-es'
import { useOnScreen } from '@/core/hooks/useOnScreen'

export const ShadowBox = ({ sx, children }: BoxProps) => {
  const topRef = useRef<HTMLDivElement>()
  const isTopVisible = useOnScreen(topRef, '10px')
  const bottomRef = useRef<HTMLDivElement>()
  const isBottomVisible = useOnScreen(bottomRef, '10px')

  return (
    <Box
      sx={theme => ({
        ...((isFunction(sx) ? sx(theme) : sx) as any),
        overflowY: 'auto',
        paddingRight: { lg: isTopVisible && isBottomVisible ? 0 : 1 },
        maskImage: {
          lg:
            isTopVisible && isBottomVisible
              ? null
              : isBottomVisible
              ? 'linear-gradient(to top, black 0%, black 6%, black 94%, transparent 100%)'
              : isTopVisible
              ? 'linear-gradient(to top, transparent 0%, black 6%, black 94%, black 100%)'
              : 'linear-gradient(to top, transparent 0%, black 6%, black 94%, transparent 100%)'
        }
      })}
    >
      <Box ref={topRef} />
      {children}
      <Box ref={bottomRef} />
    </Box>
  )
}
