import Table, { TableProps } from '@mui/material/Table'
import TableBody, { TableBodyProps } from '@mui/material/TableBody'
import TableContainer, {
  TableContainerProps
} from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow, { TableRowProps } from '@mui/material/TableRow'
import { isFunction } from 'lodash-es'
import Box from '@mui/material/Box'
import TableCell from '@mui/material/TableCell'
import { TableCellProps } from '@mui/material'
import { forwardRef } from 'react'
import {
  colWidthSm,
  colWidthXs,
  contentCellSx,
  stickyCellInnerSx,
  stickyCellSx
} from '../../pages/Competition/components/teams/stickyCellSx'
import { calcElevationString } from '@/style/palette'
import { useIsMobile } from '@/core/hooks/useMediaQueries'
import { useLeftRightScrolled } from '@/core/hooks/useLeftRightScrolled'

const shadow = `rgba(0, 0, 0, 0.75)`
export const RcTableContainer = ({
  headerHeight,
  ...props
}: TableContainerProps & { headerHeight?: number }) => {
  const { ref, isStart, isEnd } = useLeftRightScrolled(1)
  const isMobile = useIsMobile()
  const colWidth = isMobile ? colWidthXs : colWidthSm

  return (
    <Box
      sx={{
        px: { xs: 0, sm: 4 }
      }}
    >
      <TableContainer
        ref={ref}
        {...props}
        sx={theme => ({
          padding: 0,
          maskImage:
            isStart && isEnd
              ? null
              : isStart
              ? `linear-gradient(to right, ${shadow} 0%, ${shadow} 94%, transparent 100%)`
              : isEnd
              ? `linear-gradient(to right, ${shadow} 0%, ${shadow} ${colWidth}px, transparent ${colWidth}px, ${shadow} 315px, ${shadow} 94%, ${shadow} 100%)`
              : `linear-gradient(to right, ${shadow} 0%, ${shadow} ${colWidth}px, transparent ${colWidth}px, ${shadow} 315px, ${shadow} 94%, transparent 100%)`,
          maxHeight: {
            xs: `calc(100dvh - ${headerHeight || 272}px)`,
            sm: '100%'
          },
          '& .MuiTableCell-root,.sticky-cell': {
            borderRadius: isMobile ? '0 !important' : undefined
          },
          ...((isFunction(props.sx) ? props.sx(theme) : props.sx) as any)
        })}
      />
    </Box>
  )
}

export const RcTable = (props: TableProps) => {
  const isMobile = useIsMobile()
  return <Table stickyHeader={isMobile} {...props} />
}

export const RcTableHead = TableHead

export const RcTableBody = (props: TableBodyProps) => {
  const isMobile = useIsMobile()

  return (
    <TableBody
      {...props}
      sx={theme => ({
        '&:before': isMobile
          ? {}
          : {
              content: '"-"',
              display: 'block',
              lineHeight: '.4em',
              color: 'transparent'
            },
        '& .MuiChip-root': {
          borderRadius: 10
        },
        '& .MuiSkeleton-root': {
          width: 140,
          height: 24,
          borderRadius: 10
        },
        ...((isFunction(props.sx) ? props.sx(theme) : props.sx) as any)
      })}
    />
  )
}

export const RcTableHeaderRow = (props: TableRowProps) => {
  const isMobile = useIsMobile()
  return (
    <TableRow
      {...props}
      sx={theme => ({
        backgroundColor: theme.palette.background.paper,
        backgroundImage: calcElevationString(theme.palette.mode, [5]),
        '& .MuiTableCell-root': isMobile
          ? {
              backgroundColor: theme.palette.background.paper,
              backgroundImage: calcElevationString(theme.palette.mode, [5])
            }
          : undefined,
        '&.MuiTableRow-hover:hover': {
          backgroundColor: theme.palette.background.paper,
          backgroundImage: calcElevationString(theme.palette.mode, [5])
        },
        ...((isFunction(props.sx) ? props.sx(theme) : props.sx) as any)
      })}
    />
  )
}

export const RcTableBodyRow = (
  props: TableRowProps & {
    isFiltered?: boolean
    isNextSelected?: boolean
    isPreviousSelected?: boolean
  }
) => {
  const isMobile = useIsMobile()
  return (
    <TableRow
      {...props}
      sx={theme => ({
        opacity: props.isFiltered ? 0.5 : 1,
        userSelect: 'none',
        '&.Mui-selected': {
          backgroundColor: theme.palette.background.paper,
          backgroundImage: calcElevationString(theme.palette.mode, [3])
        },
        '&.Mui-selected .MuiTableCell-root:last-of-type': {
          borderTopRightRadius: props.isPreviousSelected
            ? 0
            : { xs: 0, sm: 15 },
          borderBottomRightRadius: props.isNextSelected ? 0 : 15
        },
        '&.Mui-selected .sticky-cell': {
          borderTopLeftRadius: props.isPreviousSelected ? 0 : { xs: 0, sm: 15 },
          borderBottomLeftRadius: props.isNextSelected ? 0 : { xs: 0, sm: 15 }
        },
        '&.Mui-selected:hover': {
          backgroundColor: theme.palette.background.paper,
          backgroundImage: calcElevationString(theme.palette.mode, [4])
        },
        '&:hover .MuiTableCell-root:not(:first-of-type)': {
          backgroundColor: theme.palette.background.paper,
          backgroundImage: calcElevationString(theme.palette.mode, [5])
        },
        '&:hover .sticky-cell': {
          backgroundColor: theme.palette.background.paper,
          backgroundImage: calcElevationString(theme.palette.mode, [
            isMobile ? 6 : 5
          ])
        },
        ...((isFunction(props.sx) ? props.sx(theme) : props.sx) as any)
      })}
    />
  )
}

export const RcTableStickyHeaderCell = ({
  children,
  ...props
}: TableCellProps) => {
  const isMobile = useIsMobile()

  return (
    <TableCell
      {...props}
      width={'100%'}
      sx={theme => ({
        ...stickyCellSx(theme),
        zIndex: 3, // other sticky table header cells are 2(???)
        ...((isFunction(props.sx) ? props.sx(theme) : props.sx) as any)
      })}
    >
      <Box
        className='sticky-cell'
        sx={theme => ({
          ...stickyCellInnerSx(theme, isMobile ? 8 : 5),
          height: 50
        })}
      >
        {children}
      </Box>
    </TableCell>
  )
}

export const RcTableStickyBodyCell = forwardRef(function RTSBC(
  { children, isSelected, ...props }: TableCellProps & { isSelected?: boolean },
  ref: any
) {
  const isMobile = useIsMobile()

  return (
    <TableCell
      sx={theme => ({
        ...stickyCellSx(theme),
        ...((isFunction(props.sx) ? props.sx(theme) : props.sx) as any)
      })}
    >
      <Box
        ref={ref}
        className='sticky-cell'
        {...props}
        sx={theme =>
          stickyCellInnerSx(
            theme,
            isMobile ? (isSelected ? 5 : 4) : isSelected ? 3 : 1
          )
        }
      >
        {children}
      </Box>
    </TableCell>
  )
})

export const RcTableCell = (props: TableCellProps) => {
  return (
    <TableCell
      {...props}
      sx={theme => ({
        ...contentCellSx(theme),
        ...((isFunction(props.sx) ? props.sx(theme) : props.sx) as any)
      })}
    />
  )
}
