import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { NetworkKind } from '@rallycry/api-suite-typescript/dist/models/NetworkKind'
import { Form, Formik, FormikHelpers } from 'formik'
import { TextField } from 'formik-mui'
import { orderBy } from 'lodash-es'
import * as Yup from 'yup'
import { LabeledField } from '../form/LabeledField'
import { ModalConfiguration } from '../modal/ModalConfiguration'
import { ConfirmingButton } from '@/components/molecules/interactive/ConfirmingButton'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { LabeledContent } from '@/components/molecules/text/LabeledContent'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useUserContactAccounts } from '@/entity/user/useUserContactAccounts'

interface FormModel {
  newEmail: string
}

export const ManageEmailForm = ({ userId }: { userId: number }) => {
  return (
    <Stack direction='column' spacing={10}>
      <UpdatePrimary userId={userId} />

      <Divider />

      <Typography variant='body1' textAlign='center' color='text.secondary'>
        <RcTrans i18nKey='administration:validate-user-email-description' />
      </Typography>
      <ValidateEmails userId={userId} />

      <Divider />
      <Typography variant='body1' textAlign='center' color='text.secondary'>
        <RcTrans i18nKey='administration:add-user-email-description' />
      </Typography>
      <AddNewEmail userId={userId} />
    </Stack>
  )
}

const UpdatePrimary = ({ userId }: { userId: number }) => {
  const { contactAccounts, updatePrimaryEmail } = useUserContactAccounts({
    idOrKey: userId
  })

  const emails = contactAccounts?.filter(it => it.network === NetworkKind.EMAIL)
  const [primaryEmail] = orderBy(emails, 'id')

  const validation = Yup.object<FormModel>({
    newEmail: Yup.string().email().required()
  })

  const handleSubmit = async (
    values: FormModel,
    helpers: FormikHelpers<FormModel>
  ) => {
    await updatePrimaryEmail(primaryEmail?.handle!, values.newEmail)
    helpers.resetForm()
  }

  return (
    <Formik
      initialValues={{ newEmail: '' }}
      validationSchema={validation}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form id='email-change-form'>
          <Stack direction='column' spacing={5}>
            <LabeledContent
              label={<RcTrans i18nKey='administration:current-email-label' />}
            >
              {primaryEmail?.handle}
            </LabeledContent>
            <LabeledField
              component={TextField}
              label={<RcTrans i18nKey='administration:new-email-label' />}
              name='newEmail'
              fullWidth
            />
            <RcButton
              fullWidth
              type='submit'
              disabled={isSubmitting}
              form='email-change-form'
            >
              <RcTrans i18nKey='shared.update' />
            </RcButton>
            <ModalConfiguration
              title={<RcTrans i18nKey={'administration:manage-emails-label'} />}
              subtitle={
                <RcTrans i18nKey='administration:update-user-email-description' />
              }
            />
          </Stack>
        </Form>
      )}
    </Formik>
  )
}

const ValidateEmails = ({ userId }: { userId: number }) => {
  const { contactAccounts, forceVerifyEmail, removeEmail } =
    useUserContactAccounts({
      idOrKey: userId
    })

  const emails = contactAccounts?.filter(it => it.network === NetworkKind.EMAIL)

  return (
    <Stack direction='column' spacing={3}>
      {orderBy(emails, 'id')?.map(email => (
        <Stack key={email.id} direction='row' spacing={1} alignItems='center'>
          <ConfirmingButton
            onClick={() => forceVerifyEmail(email?.handle!)}
            disabled={email.verified}
            icon={email.verified ? ['fal', 'check'] : ['fal', 'question']}
            message={
              <RcTrans i18nKey='administration:validate-user-email-confirmation' />
            }
            buttonName={<RcTrans i18nKey='shared.delete' />}
          />
          <ConfirmingButton
            color='error'
            onClick={() => removeEmail(email?.id!)}
            icon={['fal', 'trash']}
            message={
              <RcTrans i18nKey='administration:remove-user-email-confirmation' />
            }
            buttonName={<RcTrans i18nKey='shared.delete' />}
          />
          <Typography>{email.handle}</Typography>
        </Stack>
      ))}
    </Stack>
  )
}

const AddNewEmail = ({ userId }: { userId: number }) => {
  const { contactAccounts, forceVerifyEmail } = useUserContactAccounts({
    idOrKey: userId
  })

  const emails = contactAccounts?.filter(it => it.network === NetworkKind.EMAIL)

  const validation = Yup.object<FormModel>({
    newEmail: Yup.string().email().required()
  })

  const handleSubmit = async (
    values: FormModel,
    helpers: FormikHelpers<FormModel>
  ) => {
    await forceVerifyEmail(values.newEmail, emails.length + 1)
    helpers.resetForm()
  }

  return (
    <Formik
      initialValues={{ newEmail: '' }}
      validationSchema={validation}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form id='add-email-form'>
          <Stack direction='column' spacing={5}>
            <LabeledField
              component={TextField}
              label={<RcTrans i18nKey='administration:new-email-label' />}
              name='newEmail'
              fullWidth
            />
            <RcButton
              fullWidth
              type='submit'
              disabled={isSubmitting}
              form='add-email-form'
            >
              <RcTrans i18nKey='shared.add' />
            </RcButton>
          </Stack>
        </Form>
      )}
    </Formik>
  )
}
