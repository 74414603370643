import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { StackProps } from '@mui/material/Stack'
import { ContactAccountResource } from '@rallycry/api-suite-typescript/dist/models/ContactAccountResource'
import React, { useState } from 'react'
import { NetworkKind } from '@rallycry/api-suite-typescript'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import { AvatarText } from '@/components/molecules/text/AvatarText'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { TranslationNamespace } from '@/core/translation'
import { RcIcon } from '@/components/atoms/RcIcon'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'
import { useNetworkKinds } from '@/core/hooks/i18n/useNetworkKind'
import { EllipsisBox } from '@/components/molecules/text/EllipsisBox'
import { PreventParentClick } from '@/components/molecules/interactive/PreventParentClick'

export interface UserNetworkProps extends StackProps {
  account: ContactAccountResource
  rightAlign?: boolean
  condensed?: boolean
  refresh?: () => Promise<any>
}

export const UserNetwork: React.FC<UserNetworkProps> = ({
  account,
  refresh,
  condensed,
  ...rest
}) => {
  const { getNetworkIcon, getNetworkString } = useNetworkKinds()
  const { t } = useRcTranslation([TranslationNamespace.Profile]) // ph

  const isDiscord = account?.network === NetworkKind.DISCORD
  const discordLink = `discord://app/users/${account?.externalId}`
  const refreshText = t('profile:refresh-provider')
  const copyText = t('profile:copy')
  const discordText = t('profile:open-discord')
  const [refreshTooltip, setRefreshTooltip] = useState<string>(refreshText)
  const [tooltip, setTooltip] = useState<string>(copyText)

  const loc = getNetworkString(account.network!)
  const icon = getNetworkIcon(account.network!)

  const clearTooltip = () => {
    setRefreshTooltip(refreshText)
    setTooltip(copyText)
  }

  const handleRefresh = async () => {
    try {
      setRefreshTooltip(`...`)
      await refresh?.()
      setRefreshTooltip(`${t('profile:provider-refreshed')}!`)
      setTimeout(() => clearTooltip(), 4000)
    } catch (e) {
      console.log(e)
      setRefreshTooltip(t('shared.error'))
    }
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(account?.handle || '')
    setTooltip(t('profile:confirm-copy'))
    setTimeout(() => clearTooltip(), 4000)
  }

  const avatar = condensed ? (
    <Stack direction='row' alignItems='center'>
      <RcIcon icon={icon} title={loc} size='sm' color='info' />
      <Typography variant='subtitle2' color='text.secondary'>
        {account.handle}
      </Typography>
    </Stack>
  ) : (
    <EllipsisBox maxWidth={'80%'} minWidth={'70%'}>
      <AvatarText contain avatarChildren={<RcIcon icon={icon} title={loc} />}>
        <Typography variant='body1'>{account?.handle}</Typography>
        <Typography variant='subtitle1' color='text.secondary'>
          {loc}
        </Typography>
      </AvatarText>
    </EllipsisBox>
  )
  const isLoaded = !!account

  return isLoaded ? (
    <PreventParentClick>
      <Stack
        {...rest}
        direction='row'
        justifyContent='space-between'
        spacing={1}
      >
        {isDiscord ? (
          <Link
            href={discordLink}
            underline='none'
            sx={{ color: 'text.primary', minWidth: '80%' }}
          >
            {avatar}
          </Link>
        ) : (
          avatar
        )}
        {refresh && !condensed ? (
          <RcIconButton
            disableRipple
            onClick={handleRefresh}
            icon={['fal', 'refresh']}
            TooltipProps={{
              disableFocusListener: true,
              leaveTouchDelay: 4000,
              title: refreshTooltip,
              arrow: true
            }}
          />
        ) : null}

        {condensed ? null : isDiscord ? (
          <Link href={discordLink} underline='none'>
            <RcIconButton
              disableRipple
              icon={['fal', 'up-right-from-square']}
              TooltipProps={{
                disableFocusListener: true,
                leaveTouchDelay: 4000,
                title: discordText,
                arrow: true
              }}
            />
          </Link>
        ) : (
          <RcIconButton
            disableRipple
            onClick={handleCopy}
            icon={['fal', 'clone']}
            TooltipProps={{
              disableFocusListener: true,
              leaveTouchDelay: 4000,
              title: tooltip,
              arrow: true
            }}
          />
        )}
      </Stack>
    </PreventParentClick>
  ) : null
}
