import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { BracketMatchResource } from '@rallycry/api-suite-typescript/dist/models/BracketMatchResource'
import { CompetitionResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionResource'
import { IdentifiableDisplay } from '@rallycry/api-suite-typescript/dist/models/IdentifiableDisplay'
import { NamedIdentifiable } from '@rallycry/api-suite-typescript/dist/models/NamedIdentifiable'
import {
  CompetitionState,
  EmbeddedEvent,
  MatchState
} from '@rallycry/api-suite-typescript'
import { PublicMatch } from '@rallycry/social-api-typescript'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import { TeamText, MatchTeamDisplay } from './MatchTeamDisplay'
import { MatchRoundInfo } from './MatchRoundInfo'
import { CompetitionRoute, RootRoute } from '@/core/route-keys'
import { useNavigation } from '@/core/hooks/useNavigation'
import { useIsLgDown } from '@/core/hooks/useMediaQueries'
import { NavigationLink } from '@/components/organisms/navigation/NavigationLink'
import { useModal } from '@/components/organisms/modal/ModalProvider'
import { PreventParentClick } from '@/components/molecules/interactive/PreventParentClick'
import { AvatarText } from '@/components/molecules/text/AvatarText'

interface MatchDetailDisplayProps {
  bracket?: NamedIdentifiable
  competition?: CompetitionResource
  event?: Omit<EmbeddedEvent, 'timeZone'>
  extraContext?: boolean
  fullScreen?: boolean
  match?: BracketMatchResource
  ladderMatch?: PublicMatch
  team1?: NamedIdentifiable & { alternateName?: string }
  team2?: NamedIdentifiable & { alternateName?: string }
  community1?: IdentifiableDisplay
  community2?: IdentifiableDisplay
  score1?: number | string
  score2?: number | string
  isMatchComplete?: boolean
}

export const MatchDetailDisplay = ({
  bracket,
  competition,
  event,
  extraContext,
  fullScreen,
  match,
  ladderMatch,
  team1,
  team2,
  community1,
  community2,
  score1 = 0,
  score2 = 0,
  isMatchComplete
}: MatchDetailDisplayProps) => {
  const { getPath, navTo } = useNavigation()
  const { isInModalContext } = useModal()
  const isLgDown = useIsLgDown()
  const smallSize = isInModalContext || isLgDown

  const handleClick = () => {
    navTo({
      root: RootRoute.Competition,
      rootId: competition?.id,
      subRoute: CompetitionRoute.Match,
      subId: match?.id
    })
  }

  const team1Wins =
    (!!match?.winner?.id &&
      match?.winner?.id === match?.assignments?.[0]?.id) ||
    score1 > score2
  const team2Wins =
    (!!match?.winner?.id &&
      match?.winner?.id === match?.assignments?.[1]?.id) ||
    score2 > score1

  const matchChip = (
    <Chip
      size='small'
      color={
        match?.state === MatchState.PENDING ||
        competition?.state === CompetitionState.INPROGRESS
          ? 'warning'
          : match?.state === MatchState.COMPLETE ||
            competition?.state === CompetitionState.CONCLUDED
          ? 'success'
          : 'primary'
      }
      label={
        <Typography variant='subtitle1' color='text.secondary'>
          {match?.state === MatchState.PENDING ||
          competition?.state === CompetitionState.INPROGRESS
            ? 'In Progress'
            : match?.state === MatchState.COMPLETE ||
              competition?.state === CompetitionState.CONCLUDED
            ? 'Concluded'
            : 'Open'}
        </Typography>
      }
    />
  )
  if (extraContext && smallSize) {
    return (
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        onClick={handleClick}
        sx={
          isLgDown
            ? { height: '100%', p: 2, cursor: 'pointer' }
            : { height: '100%', py: 1, marginX: -1, cursor: 'pointer' }
        }
      >
        <Grid item xs>
          <AvatarText src={competition?.image} variant='rounded' size='xl'>
            <Grid container direction='column' spacing={2}>
              <Grid item>
                <Typography variant='h3' color='text.primary'>
                  {competition?.name}
                </Typography>
              </Grid>
              <Grid item>{matchChip}</Grid>
            </Grid>
          </AvatarText>
        </Grid>
      </Grid>
    )
  } else {
    return (
      <Box
        onClick={fullScreen ? undefined : handleClick}
        sx={{
          cursor: fullScreen ? 'default' : 'pointer'
        }}
      >
        <Stack direction='row' justifyContent='space-between'>
          <MatchTeamDisplay
            competition={competition}
            extraContext={extraContext}
            fullScreen={fullScreen}
            team={team1}
            community={community1}
            score={score1}
            isWinner={team1Wins}
            isMatchComplete={isMatchComplete}
            rating={ladderMatch?.entrants?.[0]?.rating}
            ratingChange={ladderMatch?.entrants?.[0]?.ratingChange}
          />
          {smallSize ? null : (
            <Stack
              display='flex'
              direction='column'
              zIndex={1}
              alignItems='center'
              justifyContent='center'
              flexGrow={1}
              height={{ xs: 70, sm: 70 }}
            >
              {extraContext && !smallSize ? (
                <PreventParentClick>
                  <NavigationLink
                    color='text.secondary'
                    variant='body2'
                    to={getPath({
                      root: RootRoute.Competition,
                      rootId: competition?.id
                    })}
                  >
                    <Typography variant='h4' mt={4}>
                      {competition?.name}
                    </Typography>
                  </NavigationLink>
                </PreventParentClick>
              ) : (
                <MatchRoundInfo
                  vertical
                  bracket={bracket}
                  match={match}
                  competition={competition}
                  event={event}
                />
              )}
            </Stack>
          )}
          <MatchTeamDisplay
            isMatchComplete={isMatchComplete}
            competition={competition}
            fullScreen={fullScreen}
            extraContext={extraContext}
            team={team2}
            community={community2}
            score={score2}
            isWinner={team2Wins}
            rightAlign
            rating={ladderMatch?.entrants?.[1]?.rating}
            ratingChange={ladderMatch?.entrants?.[1]?.ratingChange}
          />
        </Stack>
        {smallSize ? (
          <Stack direction='row' alignItems='stretch'>
            <Box width='50%'>
              <TeamText
                competition={competition}
                team={team1}
                community={community1}
                score={score1}
                isWinner={team1Wins}
                isMatchComplete={isMatchComplete}
                rating={ladderMatch?.entrants?.[0]?.rating}
                ratingChange={ladderMatch?.entrants?.[0]?.ratingChange}
              />
            </Box>
            <Box
              width='50%'
              sx={{
                textAlign: 'right'
              }}
            >
              <TeamText
                competition={competition}
                team={team2}
                community={community2}
                score={score2}
                isWinner={team2Wins}
                rightAlign
                isMatchComplete={isMatchComplete}
                rating={ladderMatch?.entrants?.[1]?.rating}
                ratingChange={ladderMatch?.entrants?.[1]?.ratingChange}
              />
            </Box>
          </Stack>
        ) : null}
      </Box>
    )
  }
}
