import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { some } from 'lodash-es'
import { useState } from 'react'
import { TeamActivity } from './TeamActivity'
import { TeamApplications } from './TeamApplications'
import { TeamAuditLog } from './TeamAuditLog'
import { TeamRoster } from './TeamRoster'
import { TeamMatches } from './TeamMatches'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useCompetitionTeam } from '@/entity/competition-team/useCompetitionTeam'
import { useBracketMatchesByTeam } from '@/entity/bracket-match/useBracketMatchesByTeam'
import { useParsedParam } from '@/core/hooks/useRouteParams'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcSuspense } from '@/components/atoms/RcSuspense'
import { useFeatures } from '@/components/providers/site/FeatureProvider'

export const TeamDetails = ({ condensed }: { condensed?: boolean }) => {
  const { featTeamMatches } = useFeatures()
  const { isCompetitionModerator, isSoloCompetition } = useCompetition()
  const { team } = useCompetitionTeam()
  const parsedId = useParsedParam('teamId')
  const { matches } = useBracketMatchesByTeam({
    request: { id: parsedId },
    paused: !featTeamMatches
  })
  const [tab, setTab] = useState(some(matches) ? 'matches' : 'roster')

  const isTeamAdmin = team?._links?.update

  return (
    <Card sx={{ minHeight: 350 }}>
      <Tabs
        value={tab}
        variant='scrollable'
        sx={{
          p: 2
        }}
      >
        {some(matches) ? (
          <Tab
            value='matches'
            onClick={() => setTab('matches')}
            label={<RcTrans i18nKey='competition:team.matches-label' />}
          />
        ) : null}
        <Tab
          value='roster'
          onClick={() => setTab('roster')}
          label={<RcTrans i18nKey='competition:team.roster-label' />}
        />
        {isTeamAdmin && !isSoloCompetition ? (
          <Tab
            value='applications'
            onClick={() => setTab('applications')}
            label={<RcTrans i18nKey='competition:team.applications-label' />}
          />
        ) : null}
        <Tab
          value='activity'
          onClick={() => setTab('activity')}
          label={<RcTrans i18nKey='competition:team.activity-label' />}
        />
        {isCompetitionModerator ? (
          <Tab
            value='audit-log'
            onClick={() => setTab('audit-log')}
            label={'Audit Log'}
          />
        ) : null}
      </Tabs>
      <Divider />
      <RcSuspense height={300}>
        <Box p={3}>
          {tab === 'roster' ? (
            <TeamRoster />
          ) : tab === 'matches' ? (
            <TeamMatches />
          ) : tab === 'applications' ? (
            <TeamApplications />
          ) : tab === 'activity' ? (
            <TeamActivity />
          ) : tab === 'audit-log' ? (
            <TeamAuditLog condensed={condensed} />
          ) : null}
        </Box>
      </RcSuspense>
    </Card>
  )
}
