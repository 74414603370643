import { AlertProps } from '@mui/material/Alert'
import { CompetitionState } from '@rallycry/api-suite-typescript/dist/models/CompetitionState'
import { EventKind } from '@rallycry/api-suite-typescript/dist/models/EventKind'
import { uniqBy } from 'lodash-es'
import Stack from '@mui/material/Stack'
import { RcTrans } from '@/components/atoms/RcTrans'
import {
  CompetitionRoute,
  CompetitionTeamsRoute,
  DiscoveryRoute,
  RootRoute
} from '@/core/route-keys'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useCompetitionEvents } from '@/entity/competition/useCompetitionEvents'
import { useCompetitionParticipant } from '@/entity/competition/useCompetitionParticipant'
import { useUserAccount } from '@/entity/user/useUserAccount'
import { useUserSelfOrgCommunities } from '@/entity/user/useUserSelfOrgCommunities'
import { useNavigation } from '@/core/hooks/useNavigation'
import { useImpersonation } from '@/components/providers/site/ImpersonationProvider'
import {
  TutorialItem,
  TutorialType
} from '@/entity/competition/useCompetitionTutorial'
import { Countdown } from '@/components/molecules/text/Countdown'
import { RcButton } from '@/components/molecules/interactive/RcButton'

export const useCompetitionAlertsRegistration = () => {
  const { navTo } = useNavigation()
  const { user } = useUserAccount()
  const { competition, isSoloCompetition, isFreeAgentMode } = useCompetition()
  const { isFullParticipant, isFreeAgentParticipant } =
    useCompetitionParticipant()
  const { isRosterLocked, events, refresh } = useCompetitionEvents({
    paused: isFullParticipant
  })
  const { isProfile } = useImpersonation()
  const { orgCommunities: selfCommunities } = useUserSelfOrgCommunities({
    paused: isFullParticipant
  })
  const { orgCommunities: parentOrgCommunities } = useUserSelfOrgCommunities({
    skipImpersonation: true,
    paused: !isProfile || isFullParticipant
  })

  const issues: TutorialItem[] = []

  // already on a team or competition over, no reason to bother with any of the rest!
  if (
    isFullParticipant ||
    isRosterLocked ||
    competition?.state === CompetitionState.CONCLUDED
  ) {
    return issues
  }

  const registration = events([EventKind.REGISTRATION])
  const rosterLock = events([EventKind.ROSTERLOCK])

  const restricted = competition?._restrictToCommunities?.map(it => it.id) || []
  const orgCommunities = uniqBy(
    [...selfCommunities, ...parentOrgCommunities],
    it => it.id
  )

  const navToRegistration = () =>
    navTo(
      {
        root: RootRoute.Competition,
        rootId: competition?.id,
        subRoute: CompetitionRoute.Team,
        subId: CompetitionTeamsRoute.All
      },
      true
    )

  if (!user) {
    issues.push({
      header: <RcTrans i18nKey='competition:issues.not-logged-in-header' />,
      type: TutorialType.TASK,
      onClick: () => navTo({ root: RootRoute.Register }),
      severity: 'warning',
      children: <RcTrans i18nKey='competition:issues.not-logged-in' />,
      action: (
        <Countdown
          target={rosterLock?.next?.startDate || registration?.current?.endDate}
          onComplete={refresh}
        />
      )
    })
  }
  // competition is restricted and user cannot ever join
  else if (
    restricted.length > 0 &&
    !orgCommunities.find(it => restricted?.includes(it?.id))
  ) {
    issues.push({
      type: TutorialType.ALERT,
      onClick: () =>
        navTo({
          root: RootRoute.Discovery,
          subRoute: DiscoveryRoute.Communities
        }),
      severity: 'error',
      children: <RcTrans i18nKey='competition:issues.not-eligible' />
    })
  }
  // enlisted as a free agent
  else if (isFreeAgentParticipant) {
    issues.push({
      header: (
        <RcTrans i18nKey='competition:team.info-free-agent-team-header' />
      ),
      type: TutorialType.TASK,
      onClick: () =>
        navTo(
          {
            root: RootRoute.Competition,
            rootId: competition?.id,
            subRoute: CompetitionRoute.Team,
            subId: CompetitionTeamsRoute.All
          },
          true
        ),
      severity: 'info',
      children: registration?.current ? (
        <RcTrans i18nKey='competition:team.info-free-agent-can-create-team' />
      ) : (
        <RcTrans i18nKey='competition:team.info-free-agent-can-join-team' />
      ),
      action: (
        <Countdown
          target={registration?.current?.endDate || rosterLock?.next?.startDate}
          onComplete={refresh}
        />
      )
    })
  }
  // registration is active
  else if (registration?.current) {
    issues.push({
      header: <RcTrans i18nKey='competition:issues.registration-header' />,
      type: TutorialType.TASK,
      onClick: navToRegistration,
      buttonName: <RcTrans i18nKey='competition:register-button' />,
      severity: 'info',
      children: isSoloCompetition ? (
        <RcTrans i18nKey='competition:issues.not-registered-team-create-solo' />
      ) : isFreeAgentMode ? (
        <RcTrans i18nKey='competition:issues.not-registered-team-create-or-join-or-free-agent' />
      ) : (
        <RcTrans i18nKey='competition:issues.not-registered-team-create-or-join' />
      ),
      action: (
        <Countdown
          target={registration?.current.endDate}
          onComplete={refresh}
        />
      )
    })
  }
  // registration in the past (but no roster lock active)
  else if (registration?.previous && !isSoloCompetition) {
    issues.push({
      header: <RcTrans i18nKey='competition:issues.registration-header' />,
      type: TutorialType.TASK,
      onClick: navToRegistration,
      buttonName: <RcTrans i18nKey='competition:register-button' />,
      severity: 'info',
      children: isFreeAgentMode ? (
        <RcTrans i18nKey='competition:issues.not-registered-team-join-or-free-agent' />
      ) : (
        <RcTrans i18nKey='competition:issues.not-registered-team-join-only' />
      ),
      action: (
        <Countdown
          target={rosterLock?.current?.endDate || rosterLock?.next?.endDate}
          onComplete={refresh}
        />
      )
    })
  }

  return issues
}
