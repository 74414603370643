import Box from '@mui/material/Box'
import Card, { CardProps } from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import dynamic from 'next/dynamic'
import { AvatarProps } from '@mui/material/Avatar'
import { isFunction } from 'lodash-es'
import { PageAvatar } from './PageAvatar'
import { ImageDisplayProps } from '@/components/atoms/ImageDisplay'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import {
  useIsMobile,
  useIsSmUp,
  useIsTablet
} from '@/core/hooks/useMediaQueries'
import { ImageWithFallback } from '@/components/atoms/ImageWithFallback'

const SvgDisplay = dynamic(
  async () => (await import('@/components/atoms/SvgDisplay')).SvgDisplay,
  { ssr: false, loading: () => <></> }
)

export interface PageHeaderProps extends CardProps {
  AvatarProps?: AvatarProps
  ImageDisplayProps?: ImageDisplayProps
  sideSvg?: any
  backgroundSvg?: any
  name?: React.ReactNode
  description?: React.ReactNode
  actions?: React.ReactNode
  centered?: boolean
  transparent?: boolean
  spacing?: number
}

/**
 * Container for page headers with avatar and banner image.
 */
export const PageHeader = ({
  AvatarProps,
  ImageDisplayProps,
  sideSvg,
  spacing,
  backgroundSvg,
  name,
  description,
  centered,
  children,
  actions,
  transparent,
  onClick,
  ...props
}: PageHeaderProps) => {
  const { noLayout } = useFeatures()
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const isSmUp = useIsSmUp()

  const headerText = (
    <Stack
      direction='column'
      alignItems={centered && !isMobile ? 'center' : 'flex-start'}
      mt={!isMobile && isTablet && sideSvg ? 12 : 2}
      mb={ImageDisplayProps && AvatarProps ? 0 : 4}
      spacing={spacing ? spacing : 2}
    >
      {name ? (
        <Typography variant='h1' noWrap>
          {name}
        </Typography>
      ) : null}
      {description ? <Box>{description}</Box> : null}
    </Stack>
  )

  const sideContent = !!sideSvg || !!backgroundSvg

  return (
    <Card
      className='page-header'
      {...props}
      elevation={1}
      sx={theme => ({
        position: 'relative',
        backgroundColor: undefined,
        borderRadius: isTablet || transparent || noLayout ? 0 : undefined,
        borderWidth: isTablet || transparent ? 0 : undefined,
        ...((isFunction(props.sx) ? props.sx(theme) : props.sx) as any)
      })}
    >
      {ImageDisplayProps ? (
        <CardMedia sx={{ aspectRatio: 4.8 }}>
          <Box style={{ position: 'relative', width: '100%', height: '100%' }}>
            <ImageWithFallback
              src={ImageDisplayProps.path!}
              alt={ImageDisplayProps.alt || 'Header Image'}
              style={{ objectFit: 'cover' }}
              fill
              priority
              quality={100}
              sizes='(max-width: 768px) 100vw, 1176px'
            />
          </Box>
        </CardMedia>
      ) : null}
      {sideContent ? (
        <Box
          sx={{
            position: isSmUp ? 'absolute' : 'relative',
            width: isSmUp ? '50%' : '100%',
            height: isSmUp ? '100%' : 256,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            top: 0,
            right: 0
          }}
        >
          {backgroundSvg ? (
            <Box
              sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                zIndex: 0
              }}
            >
              <SvgDisplay {...backgroundSvg} />
            </Box>
          ) : null}
          {sideSvg ? (
            <Box width='100%' height='100%' p={2} zIndex={1}>
              <SvgDisplay
                {...sideSvg}
                style={{ width: '100%', height: '100%' }}
              />
            </Box>
          ) : null}
        </Box>
      ) : null}

      <CardContent sx={{ px: 1 }}>
        <Container maxWidth='lg' className='page-header-content'>
          <Grid
            container
            justifyContent={centered ? 'center' : 'space-between'}
            alignItems='flex-end'
            sx={{
              marginTop: {
                xs: ImageDisplayProps && AvatarProps ? -12 : 0,
                md: ImageDisplayProps && AvatarProps ? -12 : 0
              }
            }}
          >
            {AvatarProps ? (
              <Grid item onClick={onClick}>
                <PageAvatar large={centered} {...AvatarProps} />
              </Grid>
            ) : null}
            {actions ? (
              <Grid item xs>
                <Stack direction='row' justifyContent='flex-end' spacing={1}>
                  {actions}
                </Stack>
              </Grid>
            ) : null}
            <Grid item xs={12} sm={sideContent && !AvatarProps ? 6 : 12} pb={4}>
              {headerText}
            </Grid>
          </Grid>
        </Container>
      </CardContent>
      {children ? (
        <Box px={2} pb={2}>
          {children}
        </Box>
      ) : null}
    </Card>
  )
}
