import {
  BracketMatchChannelControllerApi,
  QueryMatchChannelsRequest
} from '@rallycry/api-suite-typescript/dist/apis/BracketMatchChannelControllerApi'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useParsedParam } from '@/core/hooks/useRouteParams'
import { useController } from '@/core/hooks/useSWRApi'

export const useBracketMatchChannels = (
  options?: EntityOptions<QueryMatchChannelsRequest>
) => {
  const parsedId = useParsedParam('matchId')
  const { ctrl } = useController(BracketMatchChannelControllerApi)

  const matchId = options?.idOrKey || parsedId
  const entity = useQueryEntity({
    key: 'useBracketMatchChannels',
    expand: '_links',
    ...options,
    request: { ...options?.request, matchId },
    read: req => ctrl().queryMatchChannels(req)
  })

  return { ...entity, channels: entity.flat }
}
