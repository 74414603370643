import {
  CompetitionEntryControllerApi,
  ViewCompetitionEntryMatchesRequest
} from '@rallycry/api-suite-typescript'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useController } from '@/core/hooks/useSWRApi'

export const useBracketMatchesByTeam = (
  options?: EntityOptions<ViewCompetitionEntryMatchesRequest>
) => {
  const { ctrl } = useController(CompetitionEntryControllerApi)

  const entity = useQueryEntity({
    key: 'useBracketMatchesByTeam',
    mutable: true,
    expand:
      '_links,content{bracket{settings},event,games,assignments{entry{leader,representing{additionalOrganizations}}}}',
    ...options,
    read: req => ctrl().viewCompetitionEntryMatches(req)
  })
  return {
    ...entity,
    matches: entity.flat
  }
}
