import {
  CompetitionOfficerControllerApi,
  QueryCompetitionOfficerRequest
} from '@rallycry/api-suite-typescript/dist/apis/CompetitionOfficerControllerApi'
import { PermissionLevel } from '@rallycry/api-suite-typescript/dist/models/PermissionLevel'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useCompetitionMeta } from './useCompetitionMeta'
import { useParsedParam } from '@/core/hooks/useRouteParams'
import { useController } from '@/core/hooks/useSWRApi'

export const useCompetitionOfficers = (
  options?: EntityOptions<QueryCompetitionOfficerRequest>
) => {
  const competitionId = useParsedParam('competitionId')
  const { read: meta } = useCompetitionMeta(options)
  const { ctrl } = useController(CompetitionOfficerControllerApi)

  const entity = useQueryEntity({
    key: 'useCompetitionOfficers',
    expand: 'content{officer}',
    ...options,
    metas: [meta],
    request: { competitionId, ...options?.request },
    read: (req, { officers }) =>
      ctrl({ metas: { officers }, skipCache: true }).queryCompetitionOfficer(
        req
      )
  })

  const create = async (officerId: number, level?: PermissionLevel) => {
    await ctrl().save({
      competitionId,
      officerId,
      CompetitionOfficerSaveCommand: {
        level: level || PermissionLevel.MODERATOR
      }
    })
    await meta.mutate()
    await entity.query.mutate()
  }

  const update = async (officerId: number, level: PermissionLevel) => {
    await ctrl().save({
      competitionId,
      officerId,
      CompetitionOfficerSaveCommand: {
        level
      }
    })
    await meta.mutate()
    await entity.query.mutate()
  }

  const remove = async (officerId: number) => {
    await ctrl().removeCompetitionOfficerById({ id: officerId })
    await meta.mutate()
    await entity.query.mutate()
  }

  return { ...entity, officers: entity.flat, create, remove, update }
}
