import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/styles/useTheme'
import { CompetitionEntryResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionEntryResource'
import { CompetitionResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionResource'
import { IdentifiableDisplay } from '@rallycry/api-suite-typescript/dist/models/IdentifiableDisplay'
import { isUndefined } from 'lodash-es'
import { useState } from 'react'
import { MatchRatingAnimation } from './MatchRatingAnimation'
import { ImageWithFallback } from '@/components/atoms/ImageWithFallback'
import { RcIcon } from '@/components/atoms/RcIcon'
import { RcTrans } from '@/components/atoms/RcTrans'
import { PreventParentClick } from '@/components/molecules/interactive/PreventParentClick'
import { AvatarText } from '@/components/molecules/text/AvatarText'
import { EllipsisBox } from '@/components/molecules/text/EllipsisBox'
import { useModal } from '@/components/organisms/modal/ModalProvider'
import { NavigationLink } from '@/components/organisms/navigation/NavigationLink'
import { ExpansionType, expand } from '@/core/expand'
import { useIsLgDown, useIsMobile } from '@/core/hooks/useMediaQueries'
import { useNavigation } from '@/core/hooks/useNavigation'
import { CompetitionRoute, RootRoute } from '@/core/route-keys'
import { useCompetitionTeamImage } from '@/entity/competition-team/useCompetitionTeamImage'

type TeamDisplayProps = {
  competition?: CompetitionResource
  extraContext?: boolean
  fullScreen?: boolean
  team?: CompetitionEntryResource & { name?: string }
  community?: IdentifiableDisplay
  score?: number | string
  isWinner?: boolean
  rightAlign?: boolean
  rating?: number
  ratingChange?: number
  isMatchComplete?: boolean
}
export const MatchTeamDisplay = (props: TeamDisplayProps) => {
  const {
    competition,
    extraContext = false,
    fullScreen,
    team,
    community,
    score,
    isWinner,
    rightAlign
  } = props
  const theme = useTheme()
  const isLgDown = useIsLgDown()
  const { isInModalContext } = useModal()
  const smallSize = isInModalContext || isLgDown
  const imageOverflow = !(smallSize && !fullScreen)

  const competitionInternal =
    competition ||
    expand<CompetitionResource>(team, 'competition', ExpansionType.Competition)

  const settings = expand(
    competitionInternal,
    competitionInternal?._expanded,
    ExpansionType.CompetitionSettings
  )
  const image = useCompetitionTeamImage({
    leader: expand(team, team?._expanded, ExpansionType.CompetitionLeader),
    representing: community,
    isSingleRestrictedCommunity:
      competitionInternal?._restrictToCommunities?.length === 1,
    isSoloCompetition:
      Number(settings?.teamSizeMin) + Number(settings?.teamSizeMax) === 0
  })

  const scoreWidthOuter = smallSize ? -30 : undefined
  const scoreWidthInner = smallSize ? 5 : 0
  const hasTeamName = team?.name || team?.alternateName
  const imageAlt = team?.name || 'unknown image'

  const AvatarImage = () => (
    <Box px={5} sx={{ zIndex: 1 }}>
      <AvatarText src={image} alt={imageAlt} variant='rounded' size='xl' />
    </Box>
  )

  const FallbackImage = () => (
    <ImageWithFallback
      src={image}
      alt={imageAlt}
      width={100}
      height={100}
      style={{
        width: 'auto',
        aspectRatio: 1,
        height: extraContext || smallSize ? '100%' : '100px',
        borderBottomLeftRadius: rightAlign ? (smallSize ? 20 : 30) : 0,
        borderBottomRightRadius: rightAlign ? 0 : smallSize ? 20 : 30,
        backgroundColor: theme.palette.primary.dark,
        zIndex: 1
      }}
    />
  )

  return (
    <Paper
      square
      elevation={isWinner ? 9 : 5}
      sx={{
        width: { xs: 120, sm: 210, md: 210, lg: '38%', xl: '38%' },
        position: 'relative',
        marginBottom: extraContext || smallSize ? 0 : '25px',
        background: smallSize ? 'none' : undefined,
        height: extraContext ? 100 : imageOverflow ? 85 : 70
      }}
    >
      <Stack
        direction={rightAlign ? 'row-reverse' : 'row'}
        alignItems='center'
        spacing={extraContext ? undefined : 2}
        height='100%'
      >
        {extraContext && !isLgDown ? (
          hasTeamName ? (
            <AvatarImage />
          ) : null
        ) : hasTeamName ? (
          <FallbackImage />
        ) : null}

        {smallSize ? null : (
          <Box
            pr={rightAlign ? undefined : '65px'}
            pl={rightAlign ? '65px' : undefined}
            mx={-4}
          >
            <TeamText {...props} />
          </Box>
        )}

        <Paper
          elevation={12}
          sx={{
            position: 'absolute',
            top: 0,
            bottom: imageOverflow && smallSize ? 25 : 0,
            right: rightAlign ? scoreWidthOuter : scoreWidthInner,
            left: rightAlign ? scoreWidthInner : scoreWidthOuter,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            py: smallSize ? 1 : 4,
            borderRadius: 0,
            borderBottomLeftRadius: rightAlign ? 10 : 0,
            borderBottomRightRadius: rightAlign ? 0 : 10,
            transform: rightAlign ? 'skew(-30deg)' : 'skew(30deg)',
            transformOrigin: 'left top',
            width: {
              xs: 145,
              sm: 230,
              md: 230,
              lg: 100,
              xl: 100
            },
            zIndex: 0
          }}
        >
          <Typography
            variant={imageOverflow ? 'h1' : 'h2'}
            textAlign={smallSize ? (rightAlign ? 'left' : 'right') : 'center'}
            sx={{
              transform: rightAlign ? 'skew(30deg)' : 'skew(-30deg)',
              transformOrigin: 'center',
              width: '100%',
              px: 4
            }}
          >
            {score}
          </Typography>
        </Paper>
      </Stack>
    </Paper>
  )
}

export const TeamText = ({
  competition,
  team,
  extraContext,
  community,
  rightAlign,
  rating,
  ratingChange
}: TeamDisplayProps) => {
  const [hovered, setHovered] = useState(false)

  const isMobile = useIsMobile()
  const { getPath } = useNavigation()

  const change = ratingChange || 0
  const currentRating = rating ? Math.round(rating / 1000) : 0
  const finalRating =
    rating && change ? Math.round((rating + change) / 1000 - currentRating) : 0

  return (
    <Stack
      direction='column'
      alignItems={rightAlign ? 'flex-end' : 'flex-start'}
      spacing={1}
      p={3}
    >
      <EllipsisBox
        breakAll={isMobile ? false : true}
        lineClamp={isMobile ? 2 : 1}
        pr={rightAlign ? undefined : '30px'}
        pl={rightAlign ? '30px' : undefined}
      >
        {team?.name || team?.alternateName ? (
          <PreventParentClick>
            <NavigationLink
              variant='h3'
              underline='none'
              color='text.primary'
              textAlign={rightAlign ? 'right' : 'left'}
              to={getPath({
                root: RootRoute.Competition,
                rootId: competition?.id,
                subRoute: CompetitionRoute.Team,
                subId: team?.id
              })}
            >
              {team?.name || team?.alternateName}
            </NavigationLink>
          </PreventParentClick>
        ) : (
          <Tooltip
            title={<RcTrans i18nKey='shared.to-be-determined' />}
            sx={extraContext ? { marginRight: 8 } : undefined}
          >
            <Typography variant='h3' color='text.primary'>
              <RcTrans i18nKey='shared.to-be-determined-abbreviated' />
            </Typography>
          </Tooltip>
        )}
      </EllipsisBox>
      {community?.name ? (
        <EllipsisBox lineClamp={1}>
          <PreventParentClick>
            <NavigationLink
              variant='subtitle1'
              textAlign={rightAlign ? 'right' : 'left'}
              underline='none'
              color='textSecondary'
              to={getPath({
                root: RootRoute.Community,
                rootId: community?.id
              })}
            >
              {community.name}
            </NavigationLink>
          </PreventParentClick>
        </EllipsisBox>
      ) : null}
      {/* If team is seeded and no rating, show eed */}
      {team?.seed && isUndefined(rating) ? (
        <Typography variant='subtitle1'>
          <RcTrans i18nKey='competition:seed-label' />: {team.seed}
        </Typography>
      ) : null}
      {/* If team has rating, show rating */}
      {rating ? (
        <Stack
          direction='row'
          alignItems='center'
          spacing={2}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <MatchRatingAnimation
            start={hovered ? rating + change : rating}
            delta={hovered ? -change : change}
          />

          {finalRating > 0 ? (
            <Typography
              color='success.main'
              variant='subtitle2'
              component='span'
            >
              <RcIcon icon={['fas', 'triangle']} inline size='xs' zoom={0.75} />{' '}
              {finalRating}
            </Typography>
          ) : (
            <Typography color='error.main' variant='subtitle2' component='span'>
              <RcIcon
                icon={['fas', 'triangle']}
                inline
                size='xs'
                rotate={180}
                zoom={0.75}
              />{' '}
              {Math.abs(finalRating)}
            </Typography>
          )}
        </Stack>
      ) : null}
    </Stack>
  )
}
