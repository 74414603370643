import Chip, { ChipProps } from '@mui/material/Chip'
import {
  BracketMatchResource,
  MatchState
} from '@rallycry/api-suite-typescript'
import { RcTrans } from '@/components/atoms/RcTrans'

interface MatchStatusChipProps extends ChipProps {
  match?: BracketMatchResource
}

export const MatchStatusChip = ({ match, ...props }: MatchStatusChipProps) => {
  const complete = match?.state === MatchState.COMPLETE
  const scoresPending =
    match?.entryReportCompleted?.filter(it => !!it)?.length === 1

  return complete ? (
    <Chip
      color='success'
      label={<RcTrans i18nKey='competition:match.final' />}
      size='small'
      {...props}
    />
  ) : scoresPending ? (
    <Chip
      color='warning'
      label={<RcTrans i18nKey='competition:match.scores-pending' />}
      size='small'
      {...props}
    />
  ) : null
}
