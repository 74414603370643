import { BracketRoundControllerApi } from '@rallycry/api-suite-typescript/dist/apis/BracketRoundControllerApi'
import {
  CompetitionBracketControllerApi,
  CreateCompetitionBracketRequest
} from '@rallycry/api-suite-typescript/dist/apis/CompetitionBracketControllerApi'
import { CompetitionBracketSettingsControllerApi } from '@rallycry/api-suite-typescript/dist/apis/CompetitionBracketSettingsControllerApi'
import { CompetitionBracketSettingsResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionBracketSettingsResource'
import { CompetitionBracketSettingsUpdateCommand } from '@rallycry/api-suite-typescript/dist/models/CompetitionBracketSettingsUpdateCommand'
import { CompetitionBracketUpdateCommand } from '@rallycry/api-suite-typescript/dist/models/CompetitionBracketUpdateCommand'
import { useCompetitionMeta } from '../competition/useCompetitionMeta'
import { EntityOptions, useReadEntity } from '../useEntity'
import { useUserMeta } from '../user/useUserMeta'
import { expander, ExpansionType } from '@/core/expand'
import { useController } from '@/core/hooks/useSWRApi'
import { useParsedParam } from '@/core/hooks/useRouteParams'

export const useBracket = (options?: EntityOptions) => {
  const bracketId = useParsedParam('bracketId')
  const { read: meta } = useCompetitionMeta()
  const { read: userMeta } = useUserMeta()
  const { ctrl: settingsCtrl } = useController(
    CompetitionBracketSettingsControllerApi
  )
  const { ctrl } = useController(CompetitionBracketControllerApi)
  const { ctrl: roundCtrl } = useController(BracketRoundControllerApi)

  const entity = useReadEntity({
    key: 'useBracket',
    idOrKey: bracketId,
    expand:
      '_links,settings{submissionScoreA{bonuses},submissionScoreB{bonuses},submissionScoreC{bonuses},submissionScoreD{bonuses},submissionScoreE{bonuses}},rounds',
    ...options,
    metas: [userMeta, meta],
    read: (req, { brackets, access }) =>
      ctrl({ metas: { brackets, access } }).readCompetitionBracket(req),
    create: (req: CreateCompetitionBracketRequest) =>
      ctrl().createCompetitionBracket(req),
    update: (id, cmd: CompetitionBracketUpdateCommand) =>
      ctrl().updateCompetitionBracket({
        id,
        CompetitionBracketUpdateCommand: cmd
      })
  })

  const removeBracket = async () => {
    await ctrl().removeCompetitionBracket({ id: bracketId })
    // refreshing these metas refreshes brackets
    await Promise.allSettled([meta.mutate(), userMeta.mutate()])
  }

  const resetBracket = async () => {
    await ctrl().resetCompetitionBracket({ id: bracketId })
    await meta.mutate()
    await entity.read.mutate()
  }

  const startBracket = async () => {
    await ctrl().startCompetitionBracket({ id: bracketId })
    await meta.mutate()
    await entity.read.mutate()
  }

  const refreshBracket = async () => {
    await ctrl().refreshCompetitionBracket({ id: bracketId })
    await meta.mutate()
    await entity.read.mutate()
  }

  const finalizeBracket = async () => {
    await ctrl().finalizeCompetitionBracket({ id: bracketId })
    await meta.mutate()
    await entity.read.mutate()
  }

  const bracketSettings = expander<CompetitionBracketSettingsResource>(
    entity.read.data,
    ExpansionType.CompetitionBracketSettings
  )

  const updateBracketSettings = async (
    cmd: CompetitionBracketSettingsUpdateCommand
  ) => {
    await settingsCtrl().updateBracketSettings({
      bracketId,
      CompetitionBracketSettingsUpdateCommand: cmd
    })
    await meta.mutate()
    await entity.read.mutate()
  }

  const createRound = async (number: number, filtered: boolean) => {
    await roundCtrl().createBracketRound({
      bracketId,
      BracketRoundCreateCommand: { number, filtered }
    })
    await meta.mutate()
  }

  const removeRound = async (roundId: number) => {
    await roundCtrl().removeBracketRound({ id: roundId })
    await meta.mutate()
  }

  const updateRound = async (roundId: number, filtered: boolean) => {
    await roundCtrl().updateBracketRound({
      id: roundId,
      BracketRoundUpdateCommand: { filtered: filtered }
    })
    await meta.mutate()
  }

  const assignBracket = async (id: number, teamIds: number[]) => {
    await ctrl().assignCompetitionBracket({
      id,
      expand: '',
      CompetitionBracketAssignCommand: { entries: teamIds }
    })
  }

  return {
    ...entity,
    bracket: entity.read.data,
    bracketSettings,
    updateBracketSettings,
    removeBracket,
    resetBracket,
    startBracket,
    refreshBracket,
    finalizeBracket,
    createRound,
    removeRound,
    updateRound,
    assignBracket
  }
}
