import { BracketMatchControllerApi } from '@rallycry/api-suite-typescript/dist/apis/BracketMatchControllerApi'
import { EntityOptions, useReadEntity } from '../useEntity'
import { useParsedParam } from '@/core/hooks/useRouteParams'
import { useController } from '@/core/hooks/useSWRApi'

export const useBracketMatchMeta = (options?: EntityOptions) => {
  const matchId = useParsedParam('matchId')
  const { ctrl } = useController(BracketMatchControllerApi)

  const entity = useReadEntity({
    key: 'useBracketMatchMeta',
    idOrKey: matchId,
    ...options,
    read: req =>
      ctrl({
        expectedStatuses: [404],
        fallbackResponse: {}
      }).readBracketMatchMeta(req)
  })

  return { ...entity, meta: entity.read.data }
}
