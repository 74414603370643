import { CompetitionState } from '@rallycry/api-suite-typescript/dist/models/CompetitionState'
import { EventKind } from '@rallycry/api-suite-typescript/dist/models/EventKind'
import { isEmpty } from 'lodash-es'
import moment from 'moment-timezone'
import Stack from '@mui/material/Stack'
import { RcTrans } from '@/components/atoms/RcTrans'
import { ErrorCode } from '@/core/error-code'
import { CompetitionRoute, RootRoute } from '@/core/route-keys'
import { useBracketMatchesPersonal } from '@/entity/bracket-match/useBracketMatchesPersonal'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useCompetitionEvents } from '@/entity/competition/useCompetitionEvents'
import { useCompetitionParticipant } from '@/entity/competition/useCompetitionParticipant'
import { useNavigation } from '@/core/hooks/useNavigation'
import { useTime } from '@/core/hooks/useTime'
import {
  TutorialItem,
  TutorialType
} from '@/entity/competition/useCompetitionTutorial'
import { Countdown } from '@/components/molecules/text/Countdown'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { useCompetitionTeam } from '@/entity/competition-team/useCompetitionTeam'

export const useCompetitionAlertsTeam = () => {
  const { navTo } = useNavigation()
  const { displayDateTime } = useTime()
  const { competition, isSoloCompetition } = useCompetition()
  const { isFullParticipant } = useCompetitionParticipant()
  const { isRosterLocked, events, refresh } = useCompetitionEvents()
  const { personal } = useBracketMatchesPersonal()

  const issues: TutorialItem[] = []
  // not on a team or competition is over, no reason to bother with the rest!
  if (!isFullParticipant || competition?.state === CompetitionState.CONCLUDED) {
    return issues
  }

  const checkin = events([EventKind.CHECKIN])
  const rosterLock = events([EventKind.ROSTERLOCK])
  const soloOrTeam = isSoloCompetition ? 'solo' : 'team'

  let hasTeamIssue = false

  competition?._issues?.forEach(issue => {
    const teamRoute = {
      root: RootRoute.Competition,
      rootId: competition?.id,
      subRoute: CompetitionRoute.Team,
      subId: issue?.context?.entry as any
    }

    if (issue.code === ErrorCode.TeamSizeMin) {
      issues.push({
        header: <RcTrans i18nKey='competition:issues.team-size-header' />,
        type: isRosterLocked ? TutorialType.ALERT : TutorialType.TASK,
        severity: isRosterLocked ? 'error' : 'warning',
        children: isRosterLocked ? (
          // t('competition:issues.need-more-members-roster-locked', {count})
          <RcTrans
            i18nKey='competition:issues.need-more-members-roster-locked'
            tOptions={{
              name: issue?.context?.entryAlternateName,
              count:
                parseInt(issue?.context?.teamSizeMin?.toString()!) -
                parseInt(issue?.context?.teamSize?.toString()!)
            }}
          />
        ) : (
          // t('competition:issues.need-more-members', {count})
          <RcTrans
            i18nKey='competition:issues.need-more-members'
            tOptions={{
              name: issue?.context?.entryAlternateName,
              count:
                parseInt(issue?.context?.teamSizeMin?.toString()!) -
                parseInt(issue?.context?.teamSize?.toString()!)
            }}
          />
        ),
        action: isRosterLocked ? undefined : (
          <Stack display='flex' direction='row' spacing={1}>
            <CopyTeamInviteButton teamId={issue?.context?.entry as any} />
            <RcButton size='medium' onClick={() => navTo(teamRoute, true)}>
              <RcTrans i18nKey='competition:issues.need-more-members-review-label'>
                <RcTrans i18nKey='shared.review' />
              </RcTrans>
            </RcButton>
          </Stack>
        )
      })
      hasTeamIssue = true
    } else if (issue.code === ErrorCode.PermitCountMin) {
      issues.push({
        header: <RcTrans i18nKey='competition:issues.permit-count-header' />,
        type: TutorialType.TASK,
        onClick: () => navTo(teamRoute, true),
        severity: 'warning',
        children: (
          // t('competition:issues.need-more-paid-team', {count})
          <RcTrans
            // t('competition:issues.need-more-paid-team')
            // t('competition:issues.need-more-paid-solo')
            i18nKey={`competition:issues.need-more-paid-${soloOrTeam}`}
            tOptions={{
              name: issue?.context?.entryAlternateName,
              count: parseInt(issue?.context?.permitsNeeded?.toString()!)
            }}
          />
        )
      })
      hasTeamIssue = true
    } else if (issue.code === ErrorCode.FormMissingResponse) {
      const missing = issue.context?.missingResponses as any[]
      issues.push({
        header: <RcTrans i18nKey='competition:issues.form-missing-header' />,
        type: TutorialType.TASK,
        onClick: () => navTo(teamRoute, true),
        severity: 'warning',
        children: (
          // t('competition:issues.form-response-missing', {count})
          <RcTrans
            i18nKey={`competition:issues.form-response-missing`}
            tOptions={{
              name: missing?.[0]?.entryAlternateName,
              count: (missing as any[])?.reduce(
                (prev, cur) => prev + cur.participants?.length,
                0
              )
            }}
          />
        )
      })
      hasTeamIssue = true
    }
  })

  // team has resolved all issues, no checkin or match history
  if (
    !hasTeamIssue &&
    isEmpty(personal) &&
    !checkin.current &&
    !checkin.previous
  ) {
    issues.push({
      header: <RcTrans i18nKey='competition:issues.ready-to-play-header' />,
      type: TutorialType.TASK,
      dismissable: `${competition?.id}-team-ready-to-play`,
      severity: 'success',
      children: (
        <RcTrans
          i18nKey='competition:issues.ready-to-play'
          tOptions={{
            duration: moment(checkin?.next?.endDate).diff(
              checkin?.next?.startDate,
              'm'
            ),
            time: displayDateTime(checkin?.next?.startDate)
          }}
        />
      )
    })
  }

  return issues
}

export const CopyTeamInviteButton = ({ teamId }: { teamId: number }) => {
  const { copyTeamInvite } = useCompetitionTeam({ idOrKey: teamId })

  return (
    <RcButton size='medium' onClick={copyTeamInvite}>
      <RcTrans i18nKey='competition:issues.need-more-members-invite-link' />
    </RcButton>
  )
}
