import {
  MatchControllerApi,
  ViewPublicMatchRequest
} from '@rallycry/social-api-typescript'
import { EntityOptions, useQueryEntity, useReadEntity } from '../useEntity'
import { useSocialController } from '@/core/hooks/useSWRApi'

export const useMatchmMatch = (
  options?: EntityOptions<ViewPublicMatchRequest>
) => {
  const { ctrl } = useSocialController(MatchControllerApi)

  const entity = useQueryEntity({
    key: 'useMatchmMatch' + options?.request?.id,
    ...options,
    read: req => ctrl().viewPublicMatch(req)
  })

  return { ...entity, matches: entity.query.data }
}
