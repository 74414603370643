'use client'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { CompetitionEventResource } from '@rallycry/api-suite-typescript/dist/models'
import { ToolbarProps, View } from 'react-big-calendar'
import { useHotkeys } from '@mantine/hooks'
import Tooltip from '@mui/material/Tooltip'
import { BORDER_RADIUS } from './calendarTheme'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { calcElevationString } from '@/style/palette'
import { useIsMobileDevice } from '@/core/hooks/useMediaQueries'

export const CalendarToolbar = ({
  label,
  view,
  views,
  onNavigate,
  onView
}: ToolbarProps<CompetitionEventResource>) => {
  const viewsString = views as View[]
  const isMobileDevice = useIsMobileDevice()

  useHotkeys(
    [
      ['ArrowLeft', () => onNavigate('PREV')],
      ['ArrowRight', () => onNavigate('NEXT')],
      ['T', () => onNavigate('TODAY')],
      ['M', () => onView('month')],
      ['W', () => onView('week')],
      ['D', () => onView('day')]
    ],
    ['INPUT', 'TEXTAREA']
  )

  const viewDisplay = (it: View) =>
    it === 'month'
      ? 'Month'
      : it === 'week'
      ? 'Week'
      : it === 'day'
      ? 'Day'
      : ''

  return (
    <Stack
      direction='row'
      alignItems='flex-end'
      justifyContent='space-between'
      sx={{ pb: 2 }}
    >
      <Box
        sx={theme => ({
          borderRadius: BORDER_RADIUS,
          px: 2,
          py: 1,
          backgroundColor: theme.palette.primary.main,
          backgroundImage: calcElevationString(theme.palette.mode, [4]),
          minWidth: { md: 230 }
        })}
      >
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          spacing={1}
        >
          <Tooltip
            title={
              isMobileDevice ? null : (
                <>
                  <Typography textAlign='center' variant='body2'>
                    Previous {viewDisplay(view)}
                  </Typography>
                  <Typography
                    textAlign='center'
                    variant='subtitle2'
                    color='text.secondary'
                  >
                    Arrow Left
                  </Typography>
                </>
              )
            }
          >
            <RcIconButton
              icon={['fal', 'chevron-left']}
              onClick={() => onNavigate('PREV')}
            />
          </Tooltip>
          <Tooltip
            title={
              isMobileDevice ? null : (
                <>
                  <Typography textAlign='center' variant='body2'>
                    Back to Today
                  </Typography>
                  <Typography
                    textAlign='center'
                    variant='subtitle2'
                    color='text.secondary'
                  >
                    T
                  </Typography>
                </>
              )
            }
          >
            <Button variant='text' onClick={() => onNavigate('TODAY')}>
              {label}
            </Button>
          </Tooltip>
          <Tooltip
            title={
              isMobileDevice ? null : (
                <>
                  <Typography textAlign='center' variant='body2'>
                    Next {viewDisplay(view)}
                  </Typography>
                  <Typography
                    textAlign='center'
                    variant='subtitle2'
                    color='text.secondary'
                  >
                    Arrow Right
                  </Typography>
                </>
              )
            }
          >
            <RcIconButton
              icon={['fal', 'chevron-right']}
              onClick={() => onNavigate('NEXT')}
            />
          </Tooltip>
        </Stack>
      </Box>
      <Stack direction='row' alignItems='center' spacing={2}>
        {viewsString?.map(it => (
          <Tooltip
            key={it}
            title={
              isMobileDevice ? null : (
                <>
                  <Typography textAlign='center' variant='body2'>
                    View {viewDisplay(it)}
                  </Typography>
                  <Typography
                    textAlign='center'
                    variant='subtitle2'
                    color='text.secondary'
                  >
                    {viewDisplay(it)?.[0]}
                  </Typography>
                </>
              )
            }
          >
            <IconButton
              key={it}
              onClick={() => onView(it)}
              sx={theme => ({
                padding: 0,
                width: 24,
                height: 24,
                backgroundColor: theme.palette.primary.main,
                backgroundImage: calcElevationString(theme.palette.mode, [4])
              })}
            >
              <Typography
                textTransform='uppercase'
                sx={{
                  fontSize: '.7rem',
                  color: view === it ? 'text.primary' : 'text.secondary'
                }}
              >
                {it[0]}
              </Typography>
            </IconButton>
          </Tooltip>
        ))}
      </Stack>
    </Stack>
  )
}
