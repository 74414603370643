import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { CommunityKind } from '@rallycry/api-suite-typescript/dist/models/CommunityKind'
import { JoinRestriction } from '@rallycry/api-suite-typescript/dist/models/JoinRestriction'
import Box from '@mui/material/Box'
import { TeamIssueTooltip } from '../registration/TeamIssueTooltip'
import { RcTrans } from '@/components/atoms/RcTrans'
import { LabeledContent } from '@/components/molecules/text/LabeledContent'
import { CommunityAvatar } from '@/components/organisms/avatar/CommunityAvatar'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { ExpansionType, expand } from '@/core/expand'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'
import { TranslationNamespace } from '@/core/translation'
import { useCompetitionTeam } from '@/entity/competition-team/useCompetitionTeam'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useOrganizationCommunity } from '@/entity/organization/useOrganizationCommunity'
import { useCompetitionParticipant } from '@/entity/competition/useCompetitionParticipant'

export const TeamOverview = ({ condensed }: { condensed?: boolean }) => {
  const { featHideCommunity } = useFeatures()
  const { isSoloCompetition } = useCompetition()
  const { participant } = useCompetitionParticipant()
  const { team } = useCompetitionTeam()

  const isTeamAdmin = team?._links?.update
  const isOnTeam = participant?.entry?.id === team?.id

  const representing = expand(
    team,
    team?._expanded,
    ExpansionType.CompetitionCommunity
  )
  const parent = expand(
    representing,
    team?._expanded,
    ExpansionType.CommunityParent
  )
  const representingCommunity = useOrganizationCommunity({
    community: representing,
    expanded: team?._expanded
  })
  const parentCommunity = useOrganizationCommunity({
    community: parent,
    expanded: team?._expanded
  })

  const displayCommunity = parentCommunity || representingCommunity

  const { t } = useRcTranslation([TranslationNamespace.Competition])

  return (
    <Card>
      <CardContent sx={{ paddingY: 0, paddingX: condensed ? 4 : 4 }}>
        <Stack direction='column' spacing={5}>
          <Stack direction='column' spacing={1}>
            <LabeledContent
              label={
                <Stack direction='row' alignItems='center' spacing={2}>
                  <Box>
                    <RcTrans i18nKey='competition:team.overview-title' />
                  </Box>
                  {isTeamAdmin || isOnTeam ? (
                    <TeamIssueTooltip hideSuccess teamId={team?.id!} />
                  ) : null}
                </Stack>
              }
              icon={['fal', 'user']}
            >
              <Typography variant='subtitle1' color='text.secondary'>
                {t('competition:team.team-roster', {
                  count: team?._membersTotalElements
                })}
              </Typography>
            </LabeledContent>

            {isSoloCompetition ? null : (
              <LabeledContent
                icon={
                  team?.joinRestriction === JoinRestriction.NORMAL
                    ? ['fal', 'unlock']
                    : ['fal', 'lock']
                }
              >
                <Typography variant='subtitle1' color='text.secondary'>
                  {team?.joinRestriction === JoinRestriction.NORMAL ? (
                    <RcTrans i18nKey='competition:restriction.option-invite' />
                  ) : team?.joinRestriction === JoinRestriction.INVITEONLY ? (
                    <RcTrans i18nKey='competition:restriction.invite-only' />
                  ) : null}
                </Typography>
              </LabeledContent>
            )}

            {team?.lookingForMore ? (
              <LabeledContent icon={['fal', 'flag']}>
                <Typography variant='subtitle1' color='text.secondary'>
                  <RcTrans i18nKey='competition:team.looking-for-more' />
                </Typography>
              </LabeledContent>
            ) : null}
          </Stack>

          {!featHideCommunity &&
          displayCommunity &&
          displayCommunity.kind === CommunityKind.BASIC ? (
            <LabeledContent
              label={<RcTrans i18nKey='competition:team.affiliated-title' />}
            >
              <CommunityAvatar community={displayCommunity} skipTags />
            </LabeledContent>
          ) : null}
        </Stack>
      </CardContent>
    </Card>
  )
}
