import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useWindowEvent } from '@mantine/hooks'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {
  CompetitionEventCreateCommand,
  CompetitionEventResource,
  CompetitionEventUpdateCommand,
  EventKind
} from '@rallycry/api-suite-typescript'
import { groupBy, orderBy } from 'lodash-es'
import moment from 'moment-timezone'
import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useTime } from '@/core/hooks/useTime'
import { Calendar } from '@/components/organisms/calendar/Calendar'
import { SectionHeader } from '@/components/molecules/text/SectionHeader'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'

const kind = EventKind.LADDER
export const LadderScheduler = ({
  existing,
  onComplete
}: {
  existing: CompetitionEventResource[]
  onComplete: (
    created: CompetitionEventResource[],
    updated: CompetitionEventResource[],
    removed: CompetitionEventResource[]
  ) => Promise<any>
}) => {
  const [height, setHeight] = useState(600)
  const [events, setEvents] = useState<CompetitionEventResource[]>(
    existing || []
  )
  const { displayTime } = useTime()
  const { t } = useRcTranslation()
  const sorted = orderBy(events, ev => ev.startDate)
  const grouped = groupBy(sorted, ev =>
    moment(ev.startDate).format('ddd MMM DD')
  )

  useWindowEvent('resize', () => {
    setHeight(window.innerHeight - 200)
  })

  const handleCreate = () => {
    const creates = events
      .filter(ev => !existing.find(it => it.id === ev.id))
      .map(({ id, ...it }) => it)
    const removes = existing.filter(ev => !events.find(it => it.id === ev.id))
    const updates = events.filter(ev =>
      existing.find(
        it =>
          it.id === ev.id &&
          !removes.includes(it) &&
          (it.startDate !== ev.startDate || it.endDate !== ev.endDate)
      )
    )
    onComplete(creates, updates, removes)
  }

  return (
    <Grid container direction='row' spacing={2}>
      <Grid item xs={8}>
        <Box p={6} pr={4}>
          <SectionHeader
            title='Queue Time Schedule'
            subtitle={
              'Drag to select queue times and let players know when they can play. You can edit this before or after the bracket is published.'
            }
            pb={4}
          />
          <Calendar
            height={height}
            editable
            views={['week']}
            events={sorted}
            skipSidebar
            create={async (cmd: CompetitionEventCreateCommand) => {
              setEvents(e => [
                ...e,
                {
                  ...cmd,
                  kind,
                  name: t('BracketKind.LADDER'),
                  id: uuidv4() as any
                }
              ])
            }}
            update={async (id: number, cmd: CompetitionEventUpdateCommand) => {
              setEvents(e =>
                e.map(it => (it.id === id ? { ...it, ...cmd } : it))
              )
            }}
            remove={async (id: number) => {
              setEvents(e => e.filter(it => it.id !== id))
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Card
          elevation={2}
          sx={{
            p: 6,
            height: '100%',
            position: 'relative'
          }}
        >
          <Typography variant='subtitle1' color='text.secondary'>
            Queue Times
          </Typography>
          <Box sx={{ mt: 2, pr: 1, height: height, overflowY: 'auto' }}>
            <Stack direction='column' spacing={2}>
              {Object.keys(grouped).map(day => (
                <Card key={day} elevation={4} sx={{ padding: 4 }}>
                  <Typography variant='subtitle1' color='text.secondary'>
                    {day}
                  </Typography>
                  {grouped[day].map(ev => (
                    <Typography
                      key={ev.id}
                      variant='subtitle1'
                      sx={{ display: 'flex', alignItems: 'center', mt: 1 }}
                    >
                      <FontAwesomeIcon
                        icon={['fal', 'clock']}
                        size='xs'
                        style={{ marginRight: 5 }}
                      />
                      {displayTime(ev.startDate)} - {displayTime(ev.endDate)}
                    </Typography>
                  ))}
                </Card>
              ))}
            </Stack>
          </Box>
          <Stack
            direction='row'
            spacing={2}
            sx={{ position: 'absolute', bottom: 16, right: 24 }}
          >
            <Button
              size='medium'
              color='secondary'
              onClick={async () => onComplete([], [], [])}
            >
              <RcTrans i18nKey='shared.cancel' />
            </Button>
            <Button size='medium' onClick={handleCreate}>
              <RcTrans i18nKey='shared.update' />
            </Button>
          </Stack>
        </Card>
      </Grid>
    </Grid>
  )
}
