import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import { BracketMatchResource } from '@rallycry/api-suite-typescript/dist/models/BracketMatchResource'
import { CompetitionBracketResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionBracketResource'
import { CompetitionEventResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionEventResource'
import { CompetitionParticipantResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionParticipantResource'
import { CompetitionResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionResource'
import { MatchState } from '@rallycry/api-suite-typescript/dist/models/MatchState'
import { PropsWithChildren, use, useRef } from 'react'
import { isUndefined } from 'lodash-es'
import {
  BracketKind,
  CompetitionBracketSettings
} from '@rallycry/api-suite-typescript/dist/models'
import { useBracketMatchView } from '../useBracketMatchView'
import { MatchActionDisplay } from './MatchActionDisplay'
import { MatchDetailDisplay } from './MatchDetailDisplay'
import { expandById, expander, ExpansionType } from '@/core/expand'
import { RcAlertItem } from '@/components/organisms/site/RcAlertItem'
import { useModal } from '@/components/organisms/modal/ModalProvider'
import { MarkdownDisplay } from '@/components/molecules/input/MarkdownDisplay'
import { useMatchmMatch } from '@/entity/matchm/useMatchmMatch'
import { useOnScreen } from '@/core/hooks/useOnScreen'

export interface MatchCardProps extends PropsWithChildren {
  competition: CompetitionResource | undefined
  fullScreen?: boolean
  hideDescription?: boolean
  match: BracketMatchResource | undefined
  onClose?: () => any
  onReset?: () => any
  onRemove?: () => any
  onShowRoster?: () => any
  onShowRosterActive?: boolean
  participant: CompetitionParticipantResource | null | undefined
  score1?: number | string
  score2?: number | string
}

export const MatchCard = ({
  children,
  fullScreen,
  hideDescription,
  match,
  competition,
  participant,
  score1,
  score2,
  onShowRoster,
  onShowRosterActive,
  onReset,
  onRemove,
  onClose
}: MatchCardProps) => {
  const ref = useRef(null)
  const scrolled = useOnScreen(ref)

  const { team1Result, team2Result, isMatchParticipant, isMatchComplete } =
    useBracketMatchView({
      match,
      competition,
      participant
    })

  const bracket = expander<CompetitionBracketResource>(
    match,
    ExpansionType.CompetitionBracket
  )
  const competitionEvent = expander<CompetitionEventResource>(
    match,
    ExpansionType.CompetitionEvent
  )
  const settings = expandById<CompetitionBracketSettings>(
    bracket?.settings?.id,
    match?._expanded,
    ExpansionType.CompetitionBracketSettings
  )

  const { matches } = useMatchmMatch({
    paused: settings?.kind !== BracketKind.LADDER || !scrolled,
    request: { id: match?.externalId },
    suspense: false
  })
  const ladderMatch = matches?.[0]

  const team1 = team1Result.team
  const team2 = team2Result.team
  const canReportScores =
    team1Result.team &&
    team2Result.team &&
    match?.state !== MatchState.COMPLETE &&
    (isMatchParticipant || !!competition?._links?.moderate)

  const { isInModalContext } = useModal()

  const showDescription =
    match?.description && !hideDescription && !fullScreen && !isInModalContext

  return (
    <Card ref={ref} elevation={2}>
      {showDescription ? (
        <RcAlertItem
          issue={{
            variant: 'outlined',
            sx: {
              borderBottomWidth: 0,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              minHeight: undefined,
              paddingTop: 0,
              paddingBottom: 0
            },
            severity: 'info',
            children: (
              <MarkdownDisplay
                markdown={match.description}
                variant='body2'
                paragraph={false}
                textAlign='center'
              />
            )
          }}
        />
      ) : null}
      <Stack direction='column' spacing={2}>
        <MatchDetailDisplay
          isMatchComplete={isMatchComplete}
          bracket={bracket}
          competition={competition}
          fullScreen={fullScreen}
          event={competitionEvent}
          match={match}
          ladderMatch={ladderMatch}
          team1={team1}
          team2={team2}
          community1={team1Result.orgCommunity}
          community2={team2Result.orgCommunity}
          score1={
            isUndefined(team1Result.score) && isUndefined(score1)
              ? '-'
              : team1Result.score || score1
          }
          score2={
            isUndefined(team2Result.score) && isUndefined(score2)
              ? '-'
              : team2Result.score || score2
          }
        />
        {isInModalContext && !fullScreen ? null : (
          <MatchActionDisplay
            competition={competition}
            match={match}
            canReportScores={canReportScores}
            onShowRoster={onShowRoster}
            onShowRosterActive={onShowRosterActive}
            onReset={onReset}
            onRemove={onRemove}
            onClose={onClose}
          />
        )}
        {children}
      </Stack>
    </Card>
  )
}
