import {
  CompetitionEntryControllerApi,
  ViewCompetitionEntryParticipantContactAccountsRequest
} from '@rallycry/api-suite-typescript/dist/apis/CompetitionEntryControllerApi'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useParsedParam } from '@/core/hooks/useRouteParams'
import { useController } from '@/core/hooks/useSWRApi'

export const useCompetitionTeamParticipantContactAccounts = (
  options?: EntityOptions<ViewCompetitionEntryParticipantContactAccountsRequest>
) => {
  const teamId = useParsedParam('teamId')
  const { ctrl } = useController(CompetitionEntryControllerApi)

  const entity = useQueryEntity({
    key: 'viewCompetitionEntryParticipantContactAccounts',
    expand: 'content{_links}',
    ...options,
    request:
      teamId && options?.idOrKey
        ? { id: teamId, participantId: options?.idOrKey }
        : undefined,
    read: req =>
      ctrl({
        expectedStatuses: [403, 404],
        fallbackResponse: { content: [] }
      }).viewCompetitionEntryParticipantContactAccounts(req)
  })

  return { ...entity, accounts: entity.flat }
}
