import Stack from '@mui/material/Stack'
import { AlertProps } from '@mui/material/Alert'
import { takeRight } from 'lodash-es'
import { CompetitionIssueAlertTrigger } from './CompetitionAlertFormTrigger'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { ErrorCode } from '@/core/error-code'
import { RootRoute } from '@/core/route-keys'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useUserAccount } from '@/entity/user/useUserAccount'
import { useNavigation } from '@/core/hooks/useNavigation'
import { useLocalStorage } from '@/core/hooks/useLocalStorage'
import {
  TutorialItem,
  TutorialType
} from '@/entity/competition/useCompetitionTutorial'

export const useCompetitionAlertsUserForm = () => {
  const { competition } = useCompetition()
  const { user } = useUserAccount()
  const { navTo } = useNavigation()

  const issues: TutorialItem[] = []

  const missingForms = competition?._issues
    ?.filter(it => it.code === ErrorCode.FormMissingResponse)
    .flatMap(
      it =>
        (it.context?.missingResponses || []) as {
          competitionForm: {
            id: number
            formName: string
            form: number
            event: number
          }
          responses: {
            id: number
            user: number
            completed: boolean
            assigned: boolean
          }[]
        }[]
    )

  missingForms?.forEach(it => {
    const response = it?.responses?.find(it => it.user === user?.id)
    issues.push({
      header: <RcTrans i18nKey='competition:issues.form-header' />,
      type: TutorialType.TASK,
      severity: response?.completed ? 'success' : 'warning',
      children: response?.completed ? (
        <RcTrans
          i18nKey='competition:form-completed'
          tOptions={{ name: it.competitionForm?.formName }}
        />
      ) : response?.assigned ? (
        <RcTrans
          i18nKey='competition:form-assigned'
          tOptions={{ name: it.competitionForm?.formName }}
        />
      ) : (
        <RcTrans
          i18nKey='competition:form-incomplete'
          tOptions={{ name: it.competitionForm?.formName }}
        />
      ),
      action: (
        <CompetitionIssueAlertTrigger
          activation={handleOpen =>
            response?.completed || response?.assigned ? (
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                spacing={2}
              >
                <RcButton
                  onClick={() =>
                    navTo({
                      root: RootRoute.FormResponse,
                      rootId: response.id
                    })
                  }
                  size='medium'
                >
                  <RcTrans i18nKey='shared.review' />
                </RcButton>
              </Stack>
            ) : (
              <RcButton onClick={() => handleOpen()} size='medium'>
                <RcTrans i18nKey='shared.respond' />
              </RcButton>
            )
          }
          competitionFormId={it.competitionForm?.id}
          responseId={response?.id}
        />
      )
    })
  })

  return issues
}
