import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'
import { useLocalStorage } from '@/core/hooks/useLocalStorage'

export const BracketChallongeDisplayActions = ({
  isSoloCompetition
}: {
  isSoloCompetition: boolean
}) => {
  const { t } = useRcTranslation()

  const [showRepresenting, setShowRepresenting] = useLocalStorage({
    key: 'user-bracket-representing',
    defaultValue: false
  })

  return (
    <>
      {showRepresenting ? (
        <RcIconButton
          size='small'
          onClick={() => setShowRepresenting(false)}
          icon={isSoloCompetition ? ['fal', 'user'] : ['fal', 'users']}
          TooltipProps={{
            title: isSoloCompetition
              ? t('competition:bracket.view-player')
              : t('competition:bracket.view-team')
          }}
        />
      ) : (
        <RcIconButton
          size='small'
          onClick={() => setShowRepresenting(true)}
          icon={['fal', 'school']}
          TooltipProps={{
            title: t('competition:bracket.view-community', {
              communities: t('navigation.communities').toLowerCase()
            })
          }}
        />
      )}
    </>
  )
}
