import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { BracketMatchUpdateCommand } from '@rallycry/api-suite-typescript/dist/models/BracketMatchUpdateCommand'
import { EventKind } from '@rallycry/api-suite-typescript/dist/models/EventKind'
import { Form, Formik, FormikHelpers } from 'formik'
import { Select, TextField } from 'formik-mui'
import * as Yup from 'yup'
import { BracketMatchResource } from '@rallycry/api-suite-typescript/dist/models/BracketMatchResource'
import { WinCondition } from '@rallycry/api-suite-typescript'
import { CompetitionEventCreateEdit } from '../schedule/CompetitionEventCreateEdit'
import { NavigationLink as Link } from '@/components/organisms/navigation/NavigationLink'
import { RcTrans } from '@/components/atoms/RcTrans'
import { MarkdownFormikField } from '@/components/molecules/input/MarkdownFormikField'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import {
  LabeledField,
  LabeledFieldHeader
} from '@/components/organisms/form/LabeledField'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { ModalTrigger } from '@/components/organisms/modal/ModalTrigger'
import { useCompetitionEvents } from '@/entity/competition/useCompetitionEvents'
import { useTime } from '@/core/hooks/useTime'

export const MatchEditForm: React.FC<{
  match?: BracketMatchResource
  onSubmit: (
    values: BracketMatchUpdateCommand,
    helpers: FormikHelpers<BracketMatchUpdateCommand>
  ) => Promise<any>
}> = ({ match, onSubmit }) => {
  const { displayDateTime } = useTime()
  const { events } = useCompetitionEvents()

  const validation = Yup.object<BracketMatchUpdateCommand>({
    description: Yup.string(),
    winConditionAmount: Yup.number().required(),
    event: Yup.number()
  })

  const filter = [EventKind.MATCH]
  const schedule = events(filter)?.all

  if (!match) return null

  return (
    <Formik
      enableReinitialize
      initialValues={{
        description: match?.description || '',
        winConditionAmount: match?.winConditionAmount || 2,
        event: match?.event?.id || ('0' as any)
      }}
      validationSchema={validation}
      onSubmit={onSubmit}
    >
      {({ setFieldValue, isSubmitting }) => (
        <Form id='matches-edit-form'>
          <Stack display='flex' direction='column' spacing={2}>
            <Box>
              <LabeledFieldHeader
                label={<RcTrans i18nKey='shared.description' />}
              />
              <MarkdownFormikField name='description' />
            </Box>

            <LabeledField
              component={TextField}
              name='winConditionAmount'
              type='number'
              variant='outlined'
              label={
                match?.winCondition === WinCondition.FIRSTTO ? (
                  <RcTrans i18nKey='competition:bracket.first-to-label' />
                ) : (
                  <RcTrans i18nKey='competition:bracket.best-of-label' />
                )
              }
              fullWidth
            />

            <Box>
              <LabeledField
                component={Select}
                name='event'
                variant='outlined'
                label={<RcTrans i18nKey='shared.event' />}
                fullWidth
              >
                <MenuItem value={'0'}>None</MenuItem>
                {schedule?.map(it => (
                  <MenuItem key={it.id} value={it.id}>
                    <Stack direction='column' spacing={1}>
                      <Typography>{it.name}</Typography>
                      <Typography variant='subtitle1'>
                        {displayDateTime(it.startDate)} -{' '}
                        {displayDateTime(it.endDate)}
                      </Typography>
                    </Stack>
                  </MenuItem>
                ))}
              </LabeledField>
            </Box>

            <ModalTrigger
              activation={handleOpen => (
                <Typography variant='subtitle2'>
                  <Link onClick={handleOpen}>
                    <RcTrans i18nKey='competition:match.create-match-event' />
                  </Link>
                </Typography>
              )}
            >
              {({ handleClose }) => (
                <CompetitionEventCreateEdit
                  resource={{ kind: EventKind.MATCH }}
                  onComplete={function () {
                    handleClose()
                  }}
                />
              )}
            </ModalTrigger>
          </Stack>
          <ModalConfiguration
            title={<RcTrans i18nKey='competition:match.edit-match' />}
          >
            <RcButton
              variant='contained'
              fullWidth
              type='submit'
              disabled={isSubmitting}
              form='matches-edit-form'
            >
              <RcTrans i18nKey='shared.update' />
            </RcButton>
          </ModalConfiguration>
        </Form>
      )}
    </Formik>
  )
}
